import {
	Button,
	Chip,
	CircularProgress,
	Grid,
	makeStyles,
	TextField,
	Typography,
} from "@material-ui/core";
import { validate } from "email-validator";
import React, { useState } from "react";

const useStyle = makeStyles((theme) => ({
	textInput: {
		width: "100%",
	},
	error: { color: "red" },
	success: { color: "green" },
	confirmButton: {
		marginRight: theme.spacing(1),
		[theme.breakpoints.down("sm")]: {
			marginRight: 0,
			marginBottom: theme.spacing(1),
		},
	},
}));

/**
 * Component to display and modify user email informations
 * @param user current user
 * @param onSubmit callback to call when user save changes
 * @returns
 */
function AccountMail({ user, onSubmit, onConfirm }) {
	const classes = useStyle();
	const [email, setEmail] = useState(user.email);
	const [disabled, setDisabled] = useState(false);

	//handle email confirmation sending
	const handleConfirm = () => {
		onConfirm();
		setDisabled(true);
		setTimeout(() => {
			setDisabled(false);
		}, 10000);
	};

	return (
		<form>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Adresse Mail{" "}
						<Chip
							variant="outlined"
							label={
								user.emailIsConfirmed
									? "Vérifiée"
									: "Non verifiée"
							}
							className={
								user.emailIsConfirmed
									? classes.success
									: classes.error
							}
						/>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Email"
						variant="outlined"
						value={email}
						onChange={(event) => setEmail(event.target.value)}
						error={email !== "" && !validate(email)}
						helperText={
							email !== "" &&
							!validate(email) &&
							"L'adresse mail doit être valide"
						}
						required
					/>
				</Grid>

				<Grid item xs={12} align="right">
					{!user.emailIsConfirmed && (
						<Button
							className={classes.confirmButton}
							variant="contained"
							color="primary"
							disabled={disabled}
							onClick={handleConfirm}
						>
							{disabled ? (
								<>
									<CircularProgress
										size={20}
										style={{ marginRight: "16px" }}
									/>
									Envoie en cours...
								</>
							) : (
								"Renvoyer la confirmation"
							)}
						</Button>
					)}
					<Button
						variant="contained"
						color="primary"
						onClick={() => onSubmit(email)}
					>
						Enregistrer
					</Button>
				</Grid>
			</Grid>
		</form>
	);
}

export default AccountMail;
