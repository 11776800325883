/**
 * Request to create a new point on the map
 * @param {*} token current user token
 * @param {*} coordinates coordinates on the map
 * @param {*} properties properties of the new point
 * @returns
 */
const createNewPointAPI = (token, coordinates, properties) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/point/create`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-access-token": token,
				},
				body: JSON.stringify({ coordinates, properties }),
			}
		);
		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({
							message: json.message,
						});
					} else {
						reject(json.message);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export default createNewPointAPI;
