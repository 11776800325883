import { Grid, Typography } from "@material-ui/core";
import React from "react";

function WelcomeInfo() {
	return (
		<Grid container spacing={5}>
			<Grid item xs={12} align="center">
				<Typography variant="h3" color="primary">
					Bienvenue sur Patoutéou
				</Typography>
			</Grid>
			<Grid item xs={12} align="center">
				<Typography>Contenu bientôt disponible</Typography>
			</Grid>
		</Grid>
	);
}

export default WelcomeInfo;
