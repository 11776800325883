import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	makeStyles,
	Typography,
} from "@material-ui/core";
import {
	BlockRounded,
	CheckCircleRounded,
	SupervisorAccountRounded,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import LightTooltip from "../../../../components/LightToolTips";
import UserAdminDialog from "../components/UserAdminDialog";
import getAllUserWithRoleAPI from "../services/getAllUserWithRoleAPI";
import setUserActiveAPI from "../services/setUserActiveAPI";
import setUserAdminAPI from "../services/setUserAdminAPI";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
}));

function UserListContainer({ user, open, role }) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const [users, setUsers] = useState();

	//current user selected
	const [currentUser, setCurrentUser] = useState();

	//state of user informations dialog
	const [openDialog, setOpenDialog] = useState(false);

	//handle opening for current user informations
	const handleDialog = (currentUser) => {
		setCurrentUser(currentUser);
		setOpenDialog(!openDialog);
	};

	//set user administrator or not
	const onAdmin = (currentUser) => {
		setUserAdminAPI(user.token, currentUser.id).then(({ message }) => {
			enqueueSnackbar(message, {
				variant: "success",
			});
		});
	};

	//change user account status (activated or not)
	const onDisabled = (currentUser) => {
		setUserActiveAPI(user.token, currentUser.id).then(({ message }) => {
			enqueueSnackbar(message, {
				variant: "success",
			});
		});
	};

	//fetch all users with given role when accordion open
	useEffect(() => {
		if (open && !openDialog) {
			getAllUserWithRoleAPI(user.token, role).then(({ users }) => {
				setUsers(users.users);
			});
		}
		// eslint-disable-next-line
	}, [open, openDialog]);

	return (
		<div className={classes.root}>
			<List>
				{users && users.length > 0 ? (
					users.map((currentUser, index) => {
						return (
							<ListItem
								key={index}
								button
								onClick={() => {
									if (currentUser.id !== user.data.id) {
										handleDialog(currentUser);
									}
								}}
							>
								{currentUser.lastname} {currentUser.firstname}
								{currentUser.id !== user.data.id &&
									!currentUser.roles.find(
										(role) => role.name === "super_admin"
									) && (
										<ListItemSecondaryAction>
											<LightTooltip
												title={
													currentUser.roles.find(
														(role) =>
															role.name ===
															"admin"
													)
														? "Retirer des administrateurs"
														: "Ajouter aux administrateurs"
												}
											>
												<IconButton
													edge="end"
													onClick={() =>
														onAdmin(currentUser)
													}
												>
													<SupervisorAccountRounded />
												</IconButton>
											</LightTooltip>
											<LightTooltip
												title={
													currentUser.isActive
														? "Désactiver le compte"
														: "Activer le compte"
												}
											>
												<IconButton
													edge="end"
													onClick={() =>
														onDisabled(currentUser)
													}
												>
													{currentUser.isActive ? (
														<BlockRounded />
													) : (
														<CheckCircleRounded />
													)}
												</IconButton>
											</LightTooltip>
										</ListItemSecondaryAction>
									)}
							</ListItem>
						);
					})
				) : (
					<Typography> Aucun utilisateur trouvé</Typography>
				)}
			</List>
			<UserAdminDialog
				user={currentUser}
				open={openDialog}
				handleDialog={handleDialog}
			/>
		</div>
	);
}

export default UserListContainer;
