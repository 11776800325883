import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserDisconnected } from "../../../actions";
import AccountDeletionConfirm from "../components/AccountDeletionConfirm";
import deleteAccountConfirmAPI from "../services/deleteAccountConfirmAPI";

/**
 * Container to display delete account confirmation
 * @returns
 */
function AccountDeletionConfirmContainer({ secret }) {
	// get the browser history
	const history = useHistory();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	// When the user confirm his account deletion
	const onSubmit = () => {
		// call the service
		deleteAccountConfirmAPI(secret)
			.then((message) => {
				//disconnect the user
				dispatch(setUserDisconnected());
				enqueueSnackbar(message, {
					variant: "success",
				});
				// redirect to the homepage
				history.replace("/");
			})
			.catch((err) => {
				enqueueSnackbar(err, {
					variant: "error",
				});
			});
	};

	return <AccountDeletionConfirm onSubmit={onSubmit} />;
}

export default AccountDeletionConfirmContainer;
