import { useSnackbar } from "notistack";
import React from "react";
import { useSelector } from "react-redux";
import { UserSelector } from "../../../../selectors";
import ZoneItem from "../components/ZoneItem";
import modifyZoneInformationsAPI from "../services/modifyZoneInformationsAPI";

/**
 * Container for the zone item component
 * @returns
 */
function ZoneItemContainer({ zone, handleZone }) {
	const { enqueueSnackbar } = useSnackbar();

	//current user
	const user = useSelector(UserSelector);

	// Change zone informations
	const onSubmit = (zoneId, allValues) => {
		// call the service
		modifyZoneInformationsAPI(user, zoneId, allValues)
			.then(({ message, zones }) => {
				handleZone(zones);
				enqueueSnackbar(message, {
					variant: "success",
				});
			})
			.catch((err) => {
				enqueueSnackbar(err, {
					variant: "error",
				});
			});
	};

	return <ZoneItem zone={zone} onSubmit={onSubmit} />;
}

export default ZoneItemContainer;
