import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import LightTooltip from "../../../../components/LightToolTips";
import getPointsAPI from "../../../../services/getPointsAPI";
import createdAtToText from "../../../../utils/createdAtToText";
import ConfirmDeleteDialog from "../components/ConfirmDeleteDialog";
import deletePointAPI from "../services/deletePointAPI";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
}));

/**
 * Components for the list of points in admin page
 * @returns
 */
function PointListContainer({ user, open }) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	//array of points
	const [points, setPoints] = useState();

	//current point clicked
	const [currentPoint, setCurrentPoint] = useState();

	//state of delete dialog
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	//handle opening of delete dialog
	const handleDeleteDialog = () => {
		setOpenDeleteDialog(!openDeleteDialog);
	};

	//create request to delete current point selected
	const handleDelete = () => {
		deletePointAPI(user.token, currentPoint.id).then(({ message }) => {
			enqueueSnackbar(message, {
				variant: "success",
			});
			handleDeleteDialog();
		});
	};

	//fetch all points from the database
	useEffect(() => {
		if (open && !openDeleteDialog) {
			getPointsAPI(user.token).then(({ points }) => setPoints(points));
		}
		// eslint-disable-next-line
	}, [open, openDeleteDialog]);

	return (
		<div className={classes.root}>
			<List>
				{points && points.length > 0 ? (
					points.map((point, index) => {
						return (
							<ListItem key={index} button>
								{new Date(point.properties.createdAt).getDate()}{" "}
								{createdAtToText(point.properties.createdAt)} à{" "}
								{(
									new Date(
										point.properties.createdAt
									).getHours() - 2
								).toString().length === 1
									? "0" +
									  (new Date(
											point.properties.createdAt
									  ).getHours() -
											2)
									: new Date(
											point.properties.createdAt
									  ).getHours() - 2}{" "}
								H{" "}
								{new Date(point.properties.createdAt)
									.getMinutes()
									.toString().length === 1
									? "0" +
									  new Date(
											point.properties.createdAt
									  ).getMinutes()
									: new Date(
											point.properties.createdAt
									  ).getMinutes()}{" "}
								- Score : {point.properties.userCount}
								<ListItemSecondaryAction>
									<LightTooltip title="Supprimer">
										<IconButton
											edge="end"
											onClick={() => {
												setOpenDeleteDialog(true);
												setCurrentPoint(point);
											}}
										>
											<DeleteRounded />
										</IconButton>
									</LightTooltip>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})
				) : (
					<Typography> Aucun événement trouvé</Typography>
				)}
			</List>
			<ConfirmDeleteDialog
				open={openDeleteDialog}
				onClose={handleDeleteDialog}
				onDelete={handleDelete}
			/>
		</div>
	);
}

export default PointListContainer;
