import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	makeStyles,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab/";
import { useTheme } from "@material-ui/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setWelcomeStatus } from "../../../actions";

const useStyle = makeStyles((theme) => ({
	root: {
		marginBottom: "40px",
	},
	info: { textDecoration: "none", color: theme.palette.primary.light },

	warning: { marginBottom: theme.spacing(2) },
}));

/**
 * Dialog for welcome message
 * @returns
 */
function WelcomeDialog({ open, onClose }) {
	const classes = useStyle();
	const theme = useTheme();
	const dispatch = useDispatch();

	//if dialog is checked as already seen
	const [checked, setChecked] = useState(false);

	//set dailog fullsreen when mobile view
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	//handle dialog close
	const handleClose = () => {
		if (checked) {
			dispatch(setWelcomeStatus(checked));
		}
		onClose();
	};

	return (
		<Dialog
			className={classes.root}
			fullScreen={fullScreen}
			open={open}
			onClose={onClose}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogTitle id="responsive-dialog-title" align="center">
				Bienvenue sur Patoutéou
			</DialogTitle>
			<DialogContent>
				<Alert severity="warning" className={classes.warning}>
					<AlertTitle>Site en construction</AlertTitle>Certains
					contenus/fonctionnalités peuvent être manquants ou
					incomplets. Merci de nous contacter à l'adresse
					contact@patouteou.com en cas de problème.
				</Alert>
				<Typography align="justify">
					Patoutéou est une application cartographique de signalements
					d'événements liés aux troupeaux de montagne et ses chiens de
					protection.
					<br />
					Afin d'en profiter à 100%, inscrivez-vous et rejoignez notre
					communauté !
					<br />
					<br />
					<strong>ATTENTION !</strong> L'application Patoutéou a pour
					unique but de prévenir de la présence possible de troupeaux
					accompagnés ou non de chiens de protection des troupeaux
					lors de votre randonnée en montagne. Elle ne sert donc pas
					de valeur absolue quant aux potentiels aléas de la réalité.
					Les données récoltées sont issues de l'expérience des
					utilisateurs, par conséquent l'équipe de Patoutéou ne peut
					être tenue responsable des événements inexacts ou non
					actualisés, inscrits sur la carte.
					<br />
					Merci de prendre connaissance des Conditions d'utilisation
					de l'application et de garder un regard critique envers ses
					données cartographiques.
					<br />
					<br />
				</Typography>
				<Typography
					className={classes.info}
					component={Link}
					to={"/informations"}
				>
					Pour plus d'informations
				</Typography>
			</DialogContent>
			<DialogActions>
				<FormControlLabel
					control={
						<Checkbox
							checked={checked}
							onChange={(event) =>
								setChecked(event.target.checked)
							}
							name="checkedB"
							color="primary"
						/>
					}
					label="Je comprends et ne plus afficher"
				/>
				<Button
					variant="contained"
					onClick={handleClose}
					color="primary"
				>
					D'accord
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default WelcomeDialog;
