import Cookies from "js-cookie";
import { SET_SATELLITE_MAP, SET_THREE_D_MAP } from "../constants/ActionsType";

//initial state of the map
const initialState = {
	satelliteMap: Cookies.get("satelliteStyle") === "true" || false,
	isThreeD: Cookies.get("isThreeD") === "true" || false,
};

//Reducer for the current map
const MapReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SATELLITE_MAP:
			Cookies.set("satelliteStyle", action.payload.satelliteMap, {
				expires: 365,
			});
			return { ...state, satelliteMap: action.payload.satelliteMap };
		case SET_THREE_D_MAP:
			Cookies.set("isThreeD", action.payload.isThreeD, { expires: 365 });
			return { ...state, isThreeD: action.payload.isThreeD };

		default:
			return state;
	}
};

export default MapReducer;
