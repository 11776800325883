import { makeStyles, Paper } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
	box: {
		padding: 0,
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
	},
}));

/**
 * Generic component for tab panel content
 * @returns
 */
function TabPanel(props) {
	const { children, value, index, ...other } = props;
	const classes = useStyle();

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Paper elevation={3} className={classes.box}>
					{children}
				</Paper>
			)}
		</div>
	);
}

export default TabPanel;
