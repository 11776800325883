import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInformation } from "../../../../actions";
import { UserSelector } from "../../../../selectors";
import ProfileImage from "../components/ProfileImage";
import modifyProfileImageAPI from "../services/modifyProfileImageAPI";

/**
 * Container to display and modify image of user
 * @returns
 */
function ProfileImageContainer() {
	// get user from the store
	const user = useSelector(UserSelector);
	const dispatch = useDispatch();

	// to generate a popup
	const { enqueueSnackbar } = useSnackbar();

	// When user change his informations
	const onSubmit = (image) => {
		//call the service
		var formData = new FormData();
		formData.append("image", image);
		modifyProfileImageAPI(user, formData)
			.then(({ user, message }) => {
				dispatch(setUserInformation(user));
				// variant could be success, error, warning, info, or default
				enqueueSnackbar(message, {
					variant: "success",
				});
			})
			.catch((err) => {
				// variant could be success, error, warning, info, or default
				enqueueSnackbar(err, {
					variant: "error",
				});
			});
	};

	return <ProfileImage user={user.data} onSubmit={onSubmit} />;
}

export default ProfileImageContainer;
