import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import React from "react";

function ConfirmDeletionRequestDialog({ open, setOpen, onSubmit }) {
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{"Confirmer la désactivation"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Votre compte est sur le point d'être désactivé. <br />
					Un email de confirmation vous sera envoyé afin de finaliser
					la désactivation.
					<br />
					<br />{" "}
					<b>Etes-vous sûr de vouloir désactiver votre compte ?</b>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)} color="primary">
					Annuler
				</Button>
				<Button
					onClick={() => {
						onSubmit();
						setOpen(false);
					}}
					color="primary"
					variant="contained"
				>
					Désactiver
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmDeletionRequestDialog;
