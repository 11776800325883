/**
 * Send the new password to the server
 * @param {*} newPassword new password to send
 * @param {*} secret secret code to verify identity
 * @returns
 */
const changeForgottenPasswordAPI = (newPassword, secret) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/forgottenPassword/change`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					secret,
					newPassword,
				}),
			}
		);

		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve(json.message);
					} else {
						reject(json.message);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export default changeForgottenPasswordAPI;
