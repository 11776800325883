//Select the User stored in the Redux Store
export const UserSelector = ({ user }) => user;

//Select the welcome value stored in the Redux Store
export const WelcomeSelector = ({ welcome }) => welcome;

//Select the style values stored in the Redux Store
export const StyleSelector = ({ style }) => style;

//Select the Zone values stored in the Redux Store
export const ZoneSelector = ({ zone }) => zone;

//Select the Map values stored in the Redux Store
export const MapSelector = ({ map }) => map;
