import { Container, makeStyles, Paper, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setUserInformation } from "../../actions";
import { UserSelector } from "../../selectors";
import confirmMailAPI from "../../services/confirmMailAPI";
import isUserConnected from "../../utils/isUserConnected";

const useStyle = makeStyles((theme) => ({
	root: {
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	paper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}));

/**
 * Container for the mail verification component
 * @returns
 */
function MailVerification() {
	const classes = useStyle();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const connectedUser = useSelector(UserSelector);

	//get the parameters on the URL
	const { userId, userSecretCode } = useParams();

	//hook that call the server to verify the mail
	useEffect(() => {
		confirmMailAPI(userId, userSecretCode)
			.then(({ user, message }) => {
				if (isUserConnected(connectedUser)) {
					dispatch(setUserInformation(user));
				}
				setTimeout(() => {
					history.replace("/");
					enqueueSnackbar(message, {
						variant: "success",
					});
				}, 5000);
			})
			.catch((err) => {
				enqueueSnackbar(err, {
					variant: "error",
				});
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Container className={classes.root}>
			<Paper className={classes.paper} levation={3}>
				<Typography style={{ textAlign: "center" }}>
					Merci d'avoir confirmé votre adresse mail. Vous serez
					redirigé vers la page d'accueil dans quelques secondes.
				</Typography>
			</Paper>
		</Container>
	);
}

export default MailVerification;
