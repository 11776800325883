import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const LOGO_FJML = process.env.PUBLIC_URL + "/img/LOGO_FJML.png";
const LOGO_IPRA = process.env.PUBLIC_URL + "/img/LOGO_IPRA2.png";

const useStyle = makeStyles((theme) => ({
	link: {
		textDecoration: "none",
		color: theme.palette.primary.dark,
		fontSize: "1.5em",
	},
	logo: { height: "150px" },
}));

function MoreInfo() {
	const classes = useStyle();
	return (
		<Grid container spacing={5}>
			<Grid item xs={12} align="center">
				<Typography variant="h3" color="primary">
					Plus d'informations
				</Typography>
			</Grid>
			<Grid item xs={12} align="center">
				<Grid container spacing={3}>
					<Grid item xs>
						<img
							className={classes.logo}
							alt="FJML"
							src={LOGO_FJML}
						/>
					</Grid>
					<Grid item xs>
						<img
							className={classes.logo}
							alt="IPRA"
							src={LOGO_IPRA}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography>
							Site de la Fondation FJML et son Institut IPRA
						</Typography>
						<a
							className={classes.link}
							href="https://ipra-fjml.com"
							rel="noreferrer"
							target="_blank"
						>
							ipra-fjml.com
						</a>
					</Grid>
				</Grid>
			</Grid>
			<Divider variant="middle" style={{ width: "100%" }} />

			<Grid item xs={12} align="center">
				<Typography variant="h5">Nous contacter</Typography>
				<a className={classes.link} href="mailto:contact@patouteou.com">
					contact@patouteou.com
				</a>
			</Grid>
		</Grid>
	);
}

export default MoreInfo;
