import {
	Avatar,
	Button,
	Grid,
	IconButton,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { AddAPhotoRounded, DeleteForeverOutlined } from "@material-ui/icons";
import React, { useState } from "react";

const useStyle = makeStyles((theme) => ({
	avatar: { width: theme.spacing(32), height: theme.spacing(32) },
	input: {
		display: "none",
	},
}));

/**
 * Component to display and modify image of user
 * @param user current user
 * @param onSubmit callback to call when user modify his informations
 * @returns
 */
function ProfileImage({ user, onSubmit }) {
	const classes = useStyle();

	//user image uploaded on server
	const [image, setImage] = useState(null);

	//current profile image
	const [current, setCurrent] = useState(
		user.profileImage
			? `${process.env.REACT_APP_API_URL}/static/img/${user.profileImage}`
			: null
	);

	//state of the save button
	const [disabled, setDisabled] = useState(true);

	return (
		<div>
			<form>
				<Grid container alignItems="center" spacing={4}>
					<Grid item xs={12}>
						<Typography variant="h6" component="h6">
							Image de profil
						</Typography>
					</Grid>
					<Grid
						container
						item
						xs={12}
						direction="column"
						alignItems="center"
					>
						<Grid item>
							<Avatar
								className={classes.avatar}
								src={
									image ? URL.createObjectURL(image) : current
								}
							/>
						</Grid>
						<Grid item>
							<input
								accept="image/*"
								className={classes.input}
								id="icon-button-file"
								type="file"
								onChange={(e) => {
									setImage(e.target.files[0]);
									setDisabled(false);
								}}
							/>
							<label htmlFor="icon-button-file">
								<IconButton
									color="primary"
									aria-label="upload picture"
									component="span"
								>
									<AddAPhotoRounded />
								</IconButton>
							</label>
							<IconButton
								color="primary"
								aria-label="delete picture"
								component="span"
								onClick={() => {
									setImage(null);
									setCurrent(null);
									setDisabled(false);
								}}
							>
								<DeleteForeverOutlined />
							</IconButton>
						</Grid>
					</Grid>

					<Grid item xs={12} align="right">
						<Button
							disabled={disabled}
							variant="contained"
							color="primary"
							onClick={() => {
								setDisabled(true);
								onSubmit(image);
							}}
						>
							Enregistrer
						</Button>
					</Grid>
				</Grid>
			</form>
		</div>
	);
}

export default ProfileImage;
