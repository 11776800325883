import { useSnackbar } from "notistack";
import React from "react";
import { useSelector } from "react-redux";
import { UserSelector } from "../../../../selectors";
import passwordIsValid from "../../../../utils/passwordIsValid";
import AccountPassword from "../components/AccountPassword";
import modifyPasswordAPI from "../services/modifyPasswordAPI";

/**
 * Container to display and modify user password
 * @returns
 */
function AccountPasswordContainer() {
	const { enqueueSnackbar } = useSnackbar();

	// get the user from the store
	const user = useSelector(UserSelector);

	// When the user change his password
	const onSubmit = (oldPassword, newPassword, confirmNewPassword) => {
		// call the service
		if (passwordIsValid(newPassword)) {
			if (newPassword === confirmNewPassword) {
				modifyPasswordAPI(user, oldPassword, newPassword)
					.then(({ message }) => {
						enqueueSnackbar(message, {
							variant: "success",
						});
					})
					.catch((err) => {
						enqueueSnackbar(err, {
							variant: "error",
						});
					});
			} else {
				enqueueSnackbar("Les mots de passe ne sont pas identiques", {
					variant: "error",
				});
			}
		} else {
			enqueueSnackbar(
				"Le mot de passe ne respecte pas les règles de sécurité",
				{
					variant: "error",
				}
			);
		}
	};

	return <AccountPassword user={user.data} onSubmit={onSubmit} />;
}

export default AccountPasswordContainer;
