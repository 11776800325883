import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
	zoneText: {
		marginBottom: theme.spacing(4),
	},
}));

function ZoneAdminDialog({
	zone,
	users,
	open,
	handleDialog,
	handleSubmit,
	onDisabled,
}) {
	const classes = useStyles();
	const [properties, setProperties] = useState({ ownerId: "", name: "" });

	//handle the new value to change in the state
	const changeHandler = (e) => {
		setProperties({ ...properties, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		if (zone) {
			if (zone.properties) {
				setProperties({
					ownerId: zone.properties.ownerId,
					name: zone.properties.name,
				});
			}
		}
	}, [zone]);
	return (
		<Dialog
			open={open}
			onClose={() => {
				handleDialog();
				setProperties({ ownerId: "", name: "" });
			}}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Modifier la zone</DialogTitle>
			<DialogContent>
				{zone && zone.properties && (
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						spacing={2}
					>
						<Grid item md={6} xs={12}>
							<TextField
								variant="outlined"
								name="name"
								label="Nom du quartier"
								fullWidth
								value={properties.name}
								onChange={(e) => changeHandler(e)}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="demo-simple-select-outlined-label">
									Propriétaire
								</InputLabel>
								<Select
									labelId="demo-simple-select-outlined-label"
									name="ownerId"
									value={properties.ownerId || ""}
									onChange={changeHandler}
									label="Responsable"
								>
									<MenuItem value="">
										<em>Aucun</em>
									</MenuItem>
									{users &&
										users.length > 0 &&
										users.map((user, index) => {
											return (
												<MenuItem
													value={user.id}
													key={index}
												>
													{user.lastname}{" "}
													{user.firstname}
												</MenuItem>
											);
										})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={6} xs={12} align="center">
							<Typography className={classes.zoneText}>
								<br />- Présence quartier :{" "}
								{zone.properties.isPresent ? "Oui" : "Non"}
								<br />- Parc de chome :{" "}
								{zone.properties.chomePark ? "Oui " : "Non"}
								{zone.properties.chomeCrossing
									? "(Chome près d'un sentier)"
									: ""}
							</Typography>
						</Grid>
						<Grid item md={6} xs={12} align="center">
							<Button
								disabled={
									!zone.properties.isPresent &&
									!zone.properties.chomePark
								}
								variant="contained"
								color="primary"
								onClick={() => {
									onDisabled(zone.id, {
										name: zone.properties.name,
										isPresent: false,
										chomeCrossing: false,
										chomePark: false,
										ownerId: zone.properties.ownerId,
									});
								}}
							>
								Désactiver
							</Button>
						</Grid>
					</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						handleDialog();
						setProperties({ ownerId: "", name: "" });
					}}
					color="primary"
				>
					Annuler
				</Button>
				<Button
					onClick={() => {
						if (properties.name !== "") {
							handleSubmit(properties);
							setProperties({ ownerId: "", name: "" });
						}
					}}
					color="primary"
					variant="contained"
				>
					Enregistrer
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ZoneAdminDialog;
