/**
 * requeszt to delete a zone in the databse
 * @param {*} token current user token
 * @param {*} zoneId id of the zone to delete
 * @returns
 */
const deleteZoneAPI = (token, zoneId) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/admin/deleteZone`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-access-token": token,
				},
				body: JSON.stringify({ zoneId }),
			}
		);
		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({ message: json.message });
					} else {
						reject(json.message);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export default deleteZoneAPI;
