import SignUpInformations from "../components/SignUpInformations";
import SignUpLogin from "../components/SignUpLogin";
import SignUpRole from "../components/SignUpRole";

//titles for the stepper component
export const getSteps = () => {
	return [
		"Choisissez votre profil",
		"Renseignez vos informations",
		"Renseignez vos identifiants",
	];
};

//get current step component depending on index
export const getStepContent = (
	stepIndex,
	allValues,
	changeHandler,
	handleDateChange,
	handleAcceptChange
) => {
	switch (stepIndex) {
		case 0:
			return <SignUpRole values={allValues} setValues={changeHandler} />;
		case 1:
			return (
				<SignUpInformations
					values={allValues}
					setValues={changeHandler}
					handleDate={handleDateChange}
				/>
			);
		case 2:
			return (
				<SignUpLogin
					values={allValues}
					setValues={changeHandler}
					handleAccept={handleAcceptChange}
				/>
			);
		default:
			return "";
	}
};
