import {
	Avatar,
	Container,
	Grid,
	Hidden,
	makeStyles,
	Paper,
	Typography,
	withWidth,
} from "@material-ui/core";
import { VerifiedUserRounded } from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LightTooltip from "../../components/LightToolTips";
import { UserSelector } from "../../selectors";
import getUserAPI from "../../services/getUserAPI";
import createdAtToText from "../../utils/createdAtToText";
import isUserAdmin from "../../utils/isUserAdmin";
import isUserProfessionnal from "../../utils/isUserProfessionnal";
import roleToText from "../../utils/roleToText";

const useStyle = makeStyles((theme) => ({
	root: {
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(6),
	},
	avatar: { width: theme.spacing(24), height: theme.spacing(24) },
	text: {
		[theme.breakpoints.down("sm")]: { textAlign: "center" },
		margin: theme.spacing(2),
	},
	list: {
		margin: theme.spacing(2),
		fontSize: "1.3em",
	},
	account: { fontWeight: "bold" },
	signUp: { fontStyle: "italic", color: "grey" },

	verified: { color: "green" },
	notFound: { textAlign: "center", fontStyle: "italic" },
}));

/**
 * Component showing the public profile of a userProfile
 * @returns
 */
function PublicProfile() {
	const classes = useStyle();
	const user = useSelector(UserSelector);

	//get the id of the user in the url
	const { id } = useParams();

	//user to show profile
	const [userProfile, setUserProfile] = useState();

	//message to show if user not found
	const [notFound, setNotFound] = useState("");

	//fetch user from server with given id
	useEffect(() => {
		getUserAPI(id, user.token)
			.then(({ roles, user }) => {
				if (user.isActive) {
					setUserProfile({ roles: roles, data: user });
				} else {
					setNotFound("Profil désactivé");
				}
			})
			.catch((err) => {
				setNotFound("Profil inexistant");
			});
		// eslint-disable-next-line
	}, [id]);

	return (
		<Container className={classes.root}>
			<Paper className={classes.paper} elevation={3}>
				{userProfile ? (
					<Grid container direction="row" alignItems="center">
						<Grid item xs={12} md={3} align="center">
							<Avatar
								className={classes.avatar}
								src={
									userProfile.data.profileImage
										? `${process.env.REACT_APP_API_URL}/static/img/${userProfile.data.profileImage}`
										: null
								}
							/>
						</Grid>
						<Grid item xs={12} md>
							<Typography className={classes.text} variant="h4">
								{userProfile.data.firstname}{" "}
								{userProfile.data.lastname}{" "}
								{userProfile.data.emailIsConfirmed && (
									<LightTooltip title="Compte vérifié par Mail">
										<VerifiedUserRounded
											className={classes.verified}
										/>
									</LightTooltip>
								)}
							</Typography>
							<Typography
								className={clsx(classes.text, classes.account)}
							>
								Compte{" "}
								{isUserAdmin(userProfile.roles)
									? "Administrateur"
									: roleToText(userProfile.roles)}
							</Typography>
							{isUserProfessionnal(userProfile.roles) && (
								<>
									<Hidden smDown>
										<ul>
											{userProfile.data.numberCPT > 0 && (
												<li className={classes.list}>
													{userProfile.data.numberCPT}{" "}
													chien
													{userProfile.data
														.numberCPT > 1
														? "s"
														: ""}{" "}
													de protection (
													{
														userProfile.data
															.numberMale
													}{" "}
													mâle
													{userProfile.data
														.numberMale > 1
														? "s"
														: ""}{" "}
													/{" "}
													{
														userProfile.data
															.numberFemale
													}{" "}
													femelle
													{userProfile.data
														.numberFemale > 1
														? "s"
														: ""}
													)
												</li>
											)}
											{userProfile.data.numberDistrict >
												0 && (
												<li className={classes.list}>
													{
														userProfile.data
															.numberDistrict
													}{" "}
													quartier
													{userProfile.data
														.numberDistrict > 1
														? "s"
														: ""}
												</li>
											)}
										</ul>
									</Hidden>
									<Hidden mdUp>
										{userProfile.data.numberCPT > 0 && (
											<Typography
												className={classes.text}
											>
												{userProfile.data.numberCPT}{" "}
												chien
												{userProfile.data.numberCPT > 1
													? "s"
													: ""}{" "}
												de protection (
												{userProfile.data.numberMale}{" "}
												mâle
												{userProfile.data.numberMale > 1
													? "s"
													: ""}{" "}
												/{" "}
												{userProfile.data.numberFemale}{" "}
												femelle
												{userProfile.data.numberFemale >
												1
													? "s"
													: ""}
												)
											</Typography>
										)}
										{userProfile.data.numberDistrict >
											0 && (
											<Typography
												className={classes.text}
											>
												{
													userProfile.data
														.numberDistrict
												}{" "}
												quartier
												{userProfile.data
													.numberDistrict > 1
													? "s"
													: ""}
											</Typography>
										)}
									</Hidden>
								</>
							)}
							<Typography
								className={clsx(classes.text, classes.signUp)}
							>
								Inscrit
								{userProfile.data.gender === "female"
									? "e"
									: ""}{" "}
								depuis{" "}
								{createdAtToText(userProfile.data.createdAt)}
							</Typography>
						</Grid>
					</Grid>
				) : (
					<Typography
						variant="h5"
						component="h5"
						className={classes.notFound}
					>
						{notFound}
					</Typography>
				)}
			</Paper>
		</Container>
	);
}

export default withWidth()(PublicProfile);
