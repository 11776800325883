import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import React, { useState } from "react";
import PointListContainer from "../containers/PointListContainer";
import ZoneListContainer from "../containers/ZoneListContainer";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	heading: {
		fontSize: theme.typography.pxToRem(20),
		flexBasis: "33.33%",
		flexShrink: 0,
	},
}));

function AdminMapTab({ user }) {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<Accordion
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreRounded />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					<Typography className={classes.heading}>
						Quartiers
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ZoneListContainer
						user={user}
						open={expanded === "panel1"}
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === "panel2"}
				onChange={handleChange("panel2")}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreRounded />}
					aria-controls="panel2bh-content"
					id="panel2bh-header"
				>
					<Typography className={classes.heading}>
						Evénements
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<PointListContainer
						user={user}
						open={expanded === "panel2"}
					/>
				</AccordionDetails>
			</Accordion>
		</>
	);
}

export default AdminMapTab;
