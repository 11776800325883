/**
 * Transform livestock type to corresponding word
 * @param {*} livestockType type of livestock
 * @returns
 */
const livestockTypeToText = (livestockType) => {
	var res = "";
	switch (livestockType) {
		case "suckling_sheep":
			res = "Brebis allaitante";
			break;
		case "dairy_sheep":
			res = "Brebis laitière";
			break;
		case "goat":
			res = "Chèvre";
			break;
		case "suckling_cow":
			res = "Vache allaitante";
			break;
		case "dairy_cow":
			res = "Vache laitière";
			break;
		case "heifer":
			res = "Génisse";
			break;
		case "calf":
			res = "Veau";
			break;
		default:
			break;
	}
	return res;
};

export default livestockTypeToText;
