import {
	Button,
	Grid,
	IconButton,
	InputAdornment,
	makeStyles,
	TextField,
	Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { validate } from "email-validator";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ForgottenPasswordDialog from "./ForgottenPasswordDialog";

const useStyle = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
	},
	title: { textAlign: "center", marginBottom: theme.spacing(3) },
	textInput: {
		width: "100%",
	},
	action: { marginTop: theme.spacing(3) },
	forgotten: { textDecoration: "none", color: theme.palette.primary.light },
	button: {
		float: "right",
	},
}));

/**
 * COmponent for the connection form drawer
 * @returns
 */
function SignIn({ onConnect }) {
	const classes = useStyle();

	//email adress for connection
	const [email, setEmail] = useState("");

	//password for connection
	const [password, setPassword] = useState("");

	//state of the password input visibility
	const [passwordIsVisible, setPasswordVisibility] = useState(false);

	//state of the forgotten password dialog
	const [openForgotten, setOpenForgotten] = useState(false);

	//change pasword input visibility
	const handleClickShowPassword = () => {
		setPasswordVisibility(!passwordIsVisible);
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			onConnect(email, password)(event);
		}
	};

	return (
		<div className={classes.root}>
			<Typography className={classes.title} variant="h5">
				Connexion
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Email"
						variant="outlined"
						value={email}
						onChange={(event) => setEmail(event.target.value)}
						error={email !== "" && !validate(email)}
						helperText={
							email !== "" &&
							!validate(email) &&
							"L'adresse mail doit être valide"
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Mot de passe"
						variant="outlined"
						value={password}
						onChange={(event) => setPassword(event.target.value)}
						placeholder="*******"
						type={passwordIsVisible ? "text" : "password"}
						onKeyDown={handleKeyDown}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="change la visibilité du mot de passe"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{passwordIsVisible ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				direction="row"
				alignItems="center"
				justifyContent="center"
				className={classes.action}
			>
				<Grid item xs>
					<Typography
						className={classes.forgotten}
						component={Link}
						to={""}
						onClick={(event) => {
							event.preventDefault();
							setOpenForgotten(true);
						}}
					>
						Mot de passe oublié ?
					</Typography>
				</Grid>
				<Grid item xs>
					<Button
						className={classes.button}
						variant="contained"
						color="primary"
						onClick={(event) => onConnect(email, password)(event)}
					>
						Se connecter
					</Button>
				</Grid>
			</Grid>
			<ForgottenPasswordDialog
				open={openForgotten}
				setOpen={setOpenForgotten}
			/>
		</div>
	);
}

export default SignIn;
