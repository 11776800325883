/**
 * When mouve leave an element on the map
 * @param {*} currentElement the current element leaved
 * @param {*} map the current map
 * @param {*} source thye source of the element
 * @param {*} resetElement callback to reset element info
 */
const onLeaveElement = (currentElement, map, source, resetElement) => {
	if (currentElement) {
		map.current.setFeatureState(
			{
				source: source,
				id: currentElement,
			},
			{
				hover: false,
			}
		);
	}
	currentElement = null;
	resetElement();
	// Reset the cursor style
	map.current.getCanvas().style.cursor = "";
};

export default onLeaveElement;
