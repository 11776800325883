/**
 * Request to confirm mail with secret code and current user
 * @param {*} userId current user Id
 * @param {*} userSecretCode current user secret code
 * @returns
 */
const confirmMailAPI = (userId, userSecretCode) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/confirm/email`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					userId,
					userSecretCode,
				}),
			}
		);
		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({
							user: json.user,
							message: json.message,
						});
					} else {
						reject(json.message);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export default confirmMailAPI;
