import {
	SET_DARK_MODE,
	SET_SATELLITE_MAP,
	SET_THREE_D_MAP,
	SET_USER_DISCONNECTED,
	SET_USER_INFORMATIONS,
	SET_USER_LIKES,
	SET_USER_ROLES,
	SET_USER_TOKEN,
	SET_WELCOME_STATUS,
} from "../constants/ActionsType";

//Change data of the current user in the store
export const setUserInformation = (data) => ({
	type: SET_USER_INFORMATIONS,
	payload: { data },
});

//Change roles of the current user in the store
export const setUserRoles = (roles) => ({
	type: SET_USER_ROLES,
	payload: { roles },
});

//Change likes of the current user in the store
export const setUserLikes = (likes) => ({
	type: SET_USER_LIKES,
	payload: { likes },
});

//Change token of the current user in the store
export const setUserToken = (token) => ({
	type: SET_USER_TOKEN,
	payload: { token },
});

//Remove data of the current user in the store
export const setUserDisconnected = () => ({
	type: SET_USER_DISCONNECTED,
});

//Change status of the welcome dialog in the store
export const setWelcomeStatus = (checked) => ({
	type: SET_WELCOME_STATUS,
	payload: { checked },
});

//Change status of the dark mode in the store
export const setDarkMode = () => ({
	type: SET_DARK_MODE,
});

//Change status of the map style to satellite in the store
export const setSatelliteStyle = (satelliteMap) => ({
	type: SET_SATELLITE_MAP,
	payload: { satelliteMap },
});

//Change status of the 3d map style in the store
export const setThreeDMap = (isThreeD) => ({
	type: SET_THREE_D_MAP,
	payload: { isThreeD },
});
