/**
 * Fetch a spécific user with a given id
 * @param {*} id id of the user to find
 * @param {*} token connected user token
 * @returns
 */
const getUserAPI = (id, token) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/get`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-access-token": token,
				},
				body: JSON.stringify({ id }),
			}
		);

		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({
							roles: json.roles,
							user: json.user,
						});
					} else {
						resolve({ user: undefined });
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export default getUserAPI;
