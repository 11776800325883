import { Button, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

function AccountDeletionConfirm({ onSubmit }) {
	return (
		<form>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Désactivation de compte
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="error">
						Vous êtes sur le point de désactiver votre compte. Cette
						action sera irréversible. <br />
						Le compte et toutes ses données seront définitivement
						supprimer après un certain temps (voir Conditions
						d'utilisation). <br />
						Pendant cette période, vos données seront archivées et
						inaccessibles aux utilisateurs. <br />
						<br />
						Etes-vous vraiment sûr de vouloir le désactiver ?
					</Alert>
				</Grid>
				<Grid item xs={12} align="right">
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							onSubmit();
						}}
					>
						Désactiver définitivement le compte
					</Button>
				</Grid>
			</Grid>
		</form>
	);
}

export default AccountDeletionConfirm;
