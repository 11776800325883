import { Hidden, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import BottomTopBar from "../components/Appbar/BottomTopBar";
import TopBar from "../components/Appbar/Topbar";
import ScrollToTop from "../components/ScrollToTop";
import NotFoundPage from "../scenes/NotFound";
import { UserSelector } from "../selectors";
import isUserConnected from "../utils/isUserConnected";
import { connectedRouting, disconnectedRouting, routing } from "./routing";

const routeStyles = makeStyles((theme) => ({
	root: {
		minHeight: "100vh",
	},
	content: {
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			minHeight: `calc(100vh - 40px)`,
			marginBottom: "40px",
		},
	},
}));

/**
 * Component for the routing of the app
 * @returns
 */
function Routes() {
	const classes = routeStyles();
	const user = useSelector(UserSelector);

	return (
		<BrowserRouter>
			<ScrollToTop />
			<div className={classes.root}>
				<Hidden smDown>
					<TopBar />
				</Hidden>
				<Hidden mdUp>
					<BottomTopBar />
				</Hidden>
				<div className={classes.content}>
					<Switch>
						{routing.map((route) => (
							<Route
								key={route.path}
								exact
								path={route.path}
								component={route.component}
							/>
						))}
						{!isUserConnected(user) &&
							disconnectedRouting.map((route) => (
								<Route
									key={route.path}
									exact
									path={route.path}
									component={route.component}
								/>
							))}
						{isUserConnected(user) &&
							connectedRouting.map((route) => (
								<Route
									key={route.path}
									exact
									path={route.path}
									component={route.component}
								/>
							))}
						<Route component={NotFoundPage} />
					</Switch>
				</div>
			</div>
		</BrowserRouter>
	);
}

export default Routes;
