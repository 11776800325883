import { Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TabPanel from "../../../components/TabPanel";
import { UserSelector } from "../../../selectors";
import isUserAdmin from "../../../utils/isUserAdmin";
import AdminMapTab from "./components/AdminMapTab";
import AdminUserTab from "./components/AdminUserTab";

function Administration() {
	const user = useSelector(UserSelector);
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			{isUserAdmin(user.roles) ? (
				<div>
					<Paper>
						<Tabs
							value={value}
							onChange={handleChange}
							indicatorColor="primary"
							centered
						>
							<Tab label="Utilisateurs" />
							<Tab label="Cartographie" />
						</Tabs>
					</Paper>
					<TabPanel value={value} index={0}>
						<AdminUserTab user={user} />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<AdminMapTab user={user} />
					</TabPanel>
				</div>
			) : (
				<Grid item xs>
					<Typography>
						Cette page est réservée aux Administrateurs.
					</Typography>
				</Grid>
			)}
		</>
	);
}

export default Administration;
