import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInformation } from "../../../../actions";
import { UserSelector } from "../../../../selectors";
import ProfileInformations from "../components/ProfileInformations";
import modifyInformationsAPI from "../services/modifyInformationsAPI";

/**
 * Container to display and modify informations of user
 * @returns
 */
function ProfileInformationsContainer() {
	const dispatch = useDispatch();

	// get user from the store
	const user = useSelector(UserSelector);

	// to generate a popup
	const { enqueueSnackbar } = useSnackbar();

	// When user change his informations
	const onSubmit = (allValues) => {
		// call the service
		if (
			allValues.birthDate !== null &&
			allValues.lastname !== "" &&
			allValues.firstname !== ""
		) {
			modifyInformationsAPI(user, allValues)
				.then(({ user, message }) => {
					dispatch(setUserInformation(user));
					enqueueSnackbar(message, {
						variant: "success",
					});
				})
				.catch((err) => {
					enqueueSnackbar(err, {
						variant: "error",
					});
				});
		} else {
			enqueueSnackbar("Merci de remplir tous les champs", {
				variant: "error",
			});
		}
	};

	return <ProfileInformations user={user.data} onSubmit={onSubmit} />;
}

export default ProfileInformationsContainer;
