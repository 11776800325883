import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ConfirmDeletionRequestDialog from "./ConfirmDeletionRequestDialog";

function AccountDeletionRequest({ onSubmit }) {
	const [open, setOpen] = useState(false);

	return (
		<>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Désactivation de compte
					</Typography>
				</Grid>

				<Grid item xs={12} align="right">
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							setOpen(true);
						}}
					>
						Désactiver mon compte
					</Button>
				</Grid>
			</Grid>
			<ConfirmDeletionRequestDialog
				open={open}
				setOpen={setOpen}
				onSubmit={onSubmit}
			/>
		</>
	);
}

export default AccountDeletionRequest;
