import { pointsId, zonesId } from "../constants";

/**
 * handle when mouse enter a zone on map
 * @param {*} currentElement current elemnt entered
 * @param {*} map current map
 * @param {*} handleZone callback to set current zone info
 * @returns
 */
const onEnterZone = (currentElement, map, setZoneHovered) => (e) => {
	map.current.getCanvas().style.cursor = "pointer";

	// Set variables equal to the current feature
	setZoneHovered({ ...e.features[0].properties });

	// Check whether features exist
	if (e.features.length > 0) {
		if (currentElement) {
			if (map.current.getSource(pointsId)) {
				map.current.setFeatureState(
					{
						source: pointsId,
						id: currentElement,
					},
					{ hover: false }
				);
			}
			map.current.setFeatureState(
				{
					source: zonesId,
					id: currentElement,
				},
				{ hover: false }
			);
		}
		// When the mouse moves over the layer, update the
		// feature state for the feature under the mouse
		map.current.setFeatureState(
			{
				source: zonesId,
				id: e.features[0].id,
			},
			{
				hover: true,
			}
		);
	}
};

export default onEnterZone;
