import createdAtToText from "../../../utils/createdAtToText";
import isToday from "../../../utils/isToday";

const LIKE = process.env.PUBLIC_URL + "/img/like-svgrepo-com.svg";
const DISLIKE = process.env.PUBLIC_URL + "/img/dislike-svgrepo-com.svg";

/**
 * Html content of the point popup
 * @param {*} point point clicked
 * @param {*} user point creator
 * @returns
 */
const pointPopupHTML = (point, user) => {
	const date = new Date(point.properties.createdAt);
	const lastVote = new Date(point.properties.lastUserVoteDate);
	return `
			<div style="border:solid 1px;border-radius: 5px;">
				<p style="color:black;text-align:center">
					<span style="font-size: 1.3em;font-weight:bold">
					${
						(date.getHours() - 2).toString().length === 1
							? "0" + (date.getHours() - 2)
							: date.getHours() - 2
					} H 
					${
						date.getMinutes().toString().length === 1
							? "0" + date.getMinutes()
							: date.getMinutes()
					}
					</span>
					<br/>
					<span style="font-size: 1.1em;color:grey">
					${isToday(date) ? "" : date.getDate()} 
					${isToday(date) ? "Aujourd'hui" : createdAtToText(point.properties.createdAt)}
					</span>
					<br/>  
					${
						user.isActive
							? `<span style="font-style:italic;color:black">Par 
						<a href="/user/${point.properties.createdBy}" style="text-decoration:none;color:grey">
							${user.firstname}
						</a>	
					</span> `
							: "<span style='font-style:italic;color:black'>Compte désactivé</span>"
					}
				</p>
				<hr class="dotted" style="margin-right:8px;margin-left:8px">
				<p style="color:black;margin:8px">
					<span style="font-size: 1.2em;font-weight:bold">
						Evénements : 
					</span>
					<br/>
					${point.properties.CPTChecked ? "- Présence de Chiens de protection<br/>" : ""}
					${point.properties.herdChecked ? "- Présence de troupeau" : ""}
					${
						point.properties.herdCrossingChecked
							? " (Le troupeau est à proximité du passage)"
							: ""
					}
					<p style="text-align:center" >
						<a href="/informations/${2}" style="text-decoration:none;color:grey;font-style:italic">
							En savoir plus
						</a>
					</p>
				</p>
			</div>
			<br/>
			<div style="position: absolute;left: 35%;">
				<img alt="like" src=${LIKE} height="20px" width="20px"/>
				<span style="font-weight:bold">
				${point.properties.isValid} 
				</span>
				<img alt="dislike" src=${DISLIKE} height="20px" width="20px" style="margin-left:10px"/>
				<span style="font-weight:bold">
				${point.properties.isNotValid} 
				</span>
			</div>
			<br/>
			<p style="text-align:center">
			<span style="font-style:italic">Dernier vote : </span>
			<span style="font-weight:bold">
				${
					point.properties.lastUserVote
						? `<img alt="like" src=${
								point.properties.lastUserVote === "like"
									? LIKE
									: DISLIKE
						  } height="12px" width="12px"/>`
						: "Aucun"
				}
			</span>
			<br/>
			<span style="font-style:italic">Heure du vote : </span>
			<span style="font-weight:bold">
			
				${
					point.properties.lastUserVoteDate
						? `${isToday(lastVote) ? "" : lastVote.getDate()} 
						${
							isToday(lastVote)
								? "Aujourd'hui"
								: createdAtToText(
										point.properties.lastUserVoteDate
								  )
						} à ${
								(lastVote.getHours() - 2).toString().length ===
								1
									? "0" + (lastVote.getHours() - 2)
									: lastVote.getHours() - 2
						  } H 
				${
					lastVote.getMinutes().toString().length === 1
						? "0" + lastVote.getMinutes()
						: lastVote.getMinutes()
				}`
						: "Aucune"
				}
			</span>
			</p>
			
			`;
};

export default pointPopupHTML;
