import Cookies from "js-cookie";
import {
	SET_USER_DISCONNECTED,
	SET_USER_INFORMATIONS,
	SET_USER_LIKES,
	SET_USER_ROLES,
	SET_USER_TOKEN,
} from "../constants/ActionsType";

//initial state of the user
const initialState = {
	token: Cookies.get("token") || "",
	data: Cookies.getJSON("data") || null,
	roles: Cookies.getJSON("roles") || null,
	likes: Cookies.getJSON("likes") || null,
};

//Reducer for the current user connected
const UserReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_INFORMATIONS:
			let data = {
				...state.data,
				...action.payload.data,
			};
			Cookies.set("data", data, { expires: 365 });
			return { ...state, data: data };
		case SET_USER_ROLES:
			Cookies.set("roles", action.payload.roles, { expires: 365 });
			return { ...state, roles: action.payload.roles };
		case SET_USER_LIKES:
			Cookies.set("likes", action.payload.likes, { expires: 365 });
			return { ...state, likes: action.payload.likes };
		case SET_USER_TOKEN:
			Cookies.set("token", action.payload.token, { expires: 365 });
			return { ...state, token: action.payload.token };
		case SET_USER_DISCONNECTED:
			Cookies.remove("token");
			Cookies.remove("data");
			Cookies.remove("roles");
			Cookies.remove("likes");
			return {
				...state,
				token: "",
				data: null,
				roles: null,
				likes: null,
			};
		default:
			return state;
	}
};

export default UserReducer;
