import {
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Paper,
} from "@material-ui/core";
import { KeyboardArrowRightRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserDisconnected } from "../../../actions";
import UserMenuHeader from "../../../components/UserMenu/UserMenuHeader";
import { UserSelector } from "../../../selectors";
import isUserAdmin from "../../../utils/isUserAdmin";
import isUserProfessionnal from "../../../utils/isUserProfessionnal";

const useStyle = makeStyles((theme) => ({
	header: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
	selected: { color: theme.palette.primary.dark },
	disconnect: { color: "red" },
}));

/**
 * Component for the User parameter menu
 * @returns
 */
function MySpaceMenu({ id }) {
	const classes = useStyle();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	//current user
	const user = useSelector(UserSelector);

	//handle user disconnect
	const onDisconnect = (event) => {
		dispatch(setUserDisconnected());
		history.replace("/");
		enqueueSnackbar(`Vous êtes déconnecté`, {
			variant: "info",
		});
	};

	return (
		<Paper elevation={3}>
			<Grid container direction="column">
				<Grid className={classes.header} item xs align="center">
					<UserMenuHeader />
				</Grid>

				{isUserProfessionnal(user.roles) && (
					<>
						<Divider />
						<List component="nav">
							<ListItem
								className={
									id === "activity" ? classes.selected : ""
								}
								button
								onClick={() => {
									history.push("/my-space/activity");
								}}
								selected={id === "activity"}
							>
								<ListItemText primary="Mon Activité" />
								<KeyboardArrowRightRounded />
							</ListItem>
						</List>
					</>
				)}
				<Divider />
				<List component="nav">
					<ListItem
						className={id === "profile" ? classes.selected : ""}
						button
						onClick={() => {
							history.push("/my-space/profile");
						}}
						selected={id === "profile"}
					>
						<ListItemText primary="Mon Profil" />
						<KeyboardArrowRightRounded />
					</ListItem>
					<ListItem
						className={id === "account" ? classes.selected : ""}
						button
						onClick={() => {
							history.push("/my-space/account");
						}}
						selected={id === "account"}
					>
						<ListItemText primary="Mon Compte" />
						<KeyboardArrowRightRounded />
					</ListItem>
					<ListItem
						className={id === "settings" ? classes.selected : ""}
						button
						onClick={() => {
							history.push("/my-space/settings");
						}}
						selected={id === "settings"}
					>
						<ListItemText primary="Paramètres" />
						<KeyboardArrowRightRounded />
					</ListItem>
				</List>
				{isUserAdmin(user.roles) && (
					<>
						<Divider />
						<List component="nav">
							<ListItem
								className={
									id === "admin" ? classes.selected : ""
								}
								button
								onClick={() => {
									history.push("/my-space/admin");
								}}
								selected={id === "admin"}
							>
								<ListItemText primary="Administration" />
								<KeyboardArrowRightRounded />
							</ListItem>
						</List>
					</>
				)}
				<Divider />
				<List component="nav">
					<ListItem button onClick={onDisconnect}>
						<ListItemText
							className={classes.disconnect}
							primary="Se déconnecter"
						/>
					</ListItem>
				</List>
			</Grid>
		</Paper>
	);
}

export default MySpaceMenu;
