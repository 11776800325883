import AccountDeletionPage from "../scenes/AccountDeletion";
import ForgottenPasswordPage from "../scenes/ForgottenPassword";
import Homepage from "../scenes/Homepage";
import InformationPage from "../scenes/Information";
import MailVerification from "../scenes/MailVerification";
import MySpace from "../scenes/MySpace";
import PublicProfile from "../scenes/PublicProfile";
import SignUpPage from "../scenes/SignUp";
import ZoneOwnerRequest from "../scenes/ZoneOwnerRequest";

//List of common routes for all users
const routing = [
	{
		path: "/",
		component: Homepage,
	},
	{
		path: "/informations/:id?",
		component: InformationPage,
	},
	{
		path: "/confirm/email/:userId/:userSecretCode",
		component: MailVerification,
	},
	{
		path: "/user/forgottenPassword/:secret",
		component: ForgottenPasswordPage,
	},
	{
		path: "/user/accountDeletion/:secret",
		component: AccountDeletionPage,
	},
];

//List of routes for non connected users
const disconnectedRouting = [
	{
		path: "/sign-up",
		component: SignUpPage,
	},
];

//List of routes for connected users
const connectedRouting = [
	{ path: "/my-space/:id", component: MySpace },
	{ path: "/user/:id", component: PublicProfile },
	{ path: "/owner/request/:name", component: ZoneOwnerRequest },
];

export { routing, disconnectedRouting, connectedRouting };
