import { Container } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";
import AccountDeletionConfirmContainer from "./containers/AccountDeletionConfirmContainer";

function AccountDeletionPage() {
	const { secret } = useParams();

	return (
		<Container>
			<AccountDeletionConfirmContainer secret={secret} />
		</Container>
	);
}

export default AccountDeletionPage;
