import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import getUserZonesAPI from "../services/getUserZonesAPI";
import ZoneItemContainer from "./ZoneItemContainer";

/**
 * Container for all user zones
 * @returns
 */
function ZoneContainer({ user }) {
	//list of current user's zones
	const [zones, setZones] = useState([]);

	//handle zone change
	const handleZone = (zones) => {
		setZones([...zones]);
	};

	//fetch all user zones when loaded
	useEffect(() => {
		if (zones.length === 0) {
			getUserZonesAPI(user.token).then(({ zones }) => {
				setZones([...zones]);
			});
		}
		// eslint-disable-next-line
	}, []);

	return (
		<Grid container alignItems="center" spacing={4}>
			<Grid item xs={12}>
				<Typography variant="h6" component="h6">
					Mes quartiers ({user.data.numberDistrict})
				</Typography>
			</Grid>
			<Grid item xs={12} style={{ padding: 0 }}>
				{zones &&
					zones.map((zone, index) => {
						return (
							<ZoneItemContainer
								key={index}
								zone={zone}
								handleZone={handleZone}
							/>
						);
					})}
			</Grid>
		</Grid>
	);
}

export default ZoneContainer;
