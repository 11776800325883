import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

function NewZoneDialog({ open, onClose, onSubmit }) {
	const { enqueueSnackbar } = useSnackbar();
	const [name, setName] = useState();

	return (
		<Dialog
			open={open}
			onClose={(event, reason) => {
				if (reason !== "backdropClick") {
					onClose();
				}
			}}
			aria-labelledby="form-dialog-title"
			disableEscapeKeyDown
		>
			<DialogTitle id="form-dialog-title" align="center">
				Nouveau quartier
			</DialogTitle>
			<DialogContent>
				<Grid container direction="column" alignItems="center">
					<Grid item xs>
						<DialogContentText>
							Ajouter un nom au nouveau quartier avant de valider
							sa création.
						</DialogContentText>
						<TextField
							variant="outlined"
							autoFocus
							margin="dense"
							id="name"
							label="Nom du quartier"
							fullWidth
							onChange={(e) => setName(e.target.value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Retour</Button>
				<Button
					onClick={() => {
						if (name) {
							onSubmit(name);
						} else {
							enqueueSnackbar("Entrez un nom pour le quartier", {
								variant: "error",
							});
						}
					}}
					color="primary"
					variant="contained"
				>
					Créer
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default NewZoneDialog;
