import getPointsAPI from "../../../services/getPointsAPI";
import getZonesAPI from "../../../services/getZonesAPI";
import isUserConnected from "../../../utils/isUserConnected";
import { pointsId, zonesId } from "../constants";

/**
 * add layer of element to the current map
 * @param {*} map current map
 * @param {*} user current user
 */
const addSourceAndLayer = (map, user, mapWithRelief) => {
	/*****************************************************
	 * SKY FEATURE
	 *****************************************************/
	if (!map.current.getLayer("sky")) {
		//add sky layer to the map (atmospheric sky)
		map.current.addLayer({
			id: "sky",
			type: "sky",
			paint: {
				"sky-opacity": [
					"interpolate",
					["linear"],
					["zoom"],
					0,
					0,
					5,
					0.3,
					8,
					1,
				],
				// set up the sky layer for atmospheric scattering
				"sky-type": "atmosphere",
				// explicitly set the position of the sun rather than allowing the sun to be attached to the main light source
				// "sky-atmosphere-sun": [297.5712738255524, 90.43200493110166], //sunset
				"sky-atmosphere-sun": [292.7144280163511, 84.13294057979844], //evening
				// set the intensity of the sun as a light source (0-100 with higher values corresponding to brighter skies)
				"sky-atmosphere-sun-intensity": 5,
			},
		});
	}

	/*****************************************************
	 * 3D FEATURE
	 *****************************************************/
	if (!map.current.getSource("mapbox-dem")) {
		//add 3D source to display the map with relief
		map.current.addSource("mapbox-dem", {
			type: "raster-dem",
			url: "mapbox://mapbox.mapbox-terrain-dem-v1",
			tileSize: 512,
			maxzoom: 14,
		});
		if (mapWithRelief) {
			// add the DEM source as a terrain layer
			map.current.setTerrain({ source: "mapbox-dem" });
		}
	}

	/*****************************************************
	 * ZONE FEATURE
	 *****************************************************/
	if (
		!map.current.getSource(zonesId) &&
		!map.current.getLayer(zonesId) &&
		!map.current.getLayer("zoneLines")
	) {
		//fetch all zones from server then add them to the map
		getZonesAPI().then(({ zones }) => {
			map.current.addSource(zonesId, {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: zones,
				},
			});

			// Add a new layer to visualize the polygon.
			map.current.addLayer({
				id: zonesId,
				type: "fill",
				source: zonesId,
				layout: {
					// Make the layer visible by default.
					visibility: "visible",
				},
				paint: {
					"fill-color": [
						"case",
						["boolean", ["get", "isPresent"], false],
						"#DB7036",
						["boolean", ["get", "chomePark"], false],
						"#E6A715",
						"#65D28E",
					],
					"fill-opacity": [
						"case",
						["boolean", ["feature-state", "hover"], false],
						0.75,
						0.5,
					],
				},
			});

			// Add a new layer to visualize the polygon contour.
			map.current.addLayer({
				id: "zoneLines",
				type: "line",
				source: zonesId,
				layout: {
					// Make the layer visible by default.
					visibility: "visible",
				},
				paint: {
					"line-color": [
						"case",
						["boolean", ["get", "isPresent"], false],
						"#8f4924",
						["boolean", ["get", "chomePark"], false],
						"#ab7c0f",
						"#3d8057",
					],
					"line-width": 1,
				},
			});
		});
	}
	if (isUserConnected(user)) {
		/*****************************************************
		 * POINT FEATURE
		 *****************************************************/
		if (
			!map.current.getSource(pointsId) &&
			!map.current.getLayer(pointsId) &&
			!map.current.getLayer("clusters") &&
			!map.current.getLayer("cluster-count")
		) {
			//fetch all points from server then add them to the map
			getPointsAPI(user.token).then(({ points }) => {
				// Add a data source containing GeoJSON data.
				map.current.addSource(pointsId, {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: points,
					},
					cluster: true,
					clusterMaxZoom: 14, // Max zoom to cluster points on
					clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
				});

				const today = new Date().toISOString().slice(0, 10);
				// Add a new layer to visualize point.
				map.current.addLayer({
					id: pointsId,
					source: pointsId,
					type: "circle",
					layout: {
						// Make the layer visible by default.
						visibility: "visible",
					},
					paint: {
						"circle-radius": [
							"case",
							[
								">=",
								["to-string", ["get", "createdAt"]],
								["to-string", today],
							],
							12,
							10,
						],
						"circle-stroke-color": "#000",
						"circle-stroke-width": [
							"case",
							["boolean", ["feature-state", "hover"], false],
							2,
							1,
						],
						"circle-stroke-opacity": [
							"case",
							[
								">=",
								["to-string", ["get", "createdAt"]],
								["to-string", today],
							],
							0.9,
							0.5,
						],
						"circle-opacity": [
							"case",
							[
								">=",
								["to-string", ["get", "createdAt"]],
								["to-string", today],
							],
							0.9,
							0.5,
						],
						"circle-color": [
							"case",
							[
								">=",
								["to-string", ["get", "createdAt"]],
								["to-string", today],
							],
							"orange",
							"grey",
						],
					},
				});

				//Add layer showing cluster point
				map.current.addLayer({
					id: "clusters",
					type: "circle",
					source: pointsId,
					filter: ["has", "point_count"],
					layout: {
						// Make the layer visible by default.
						visibility: "visible",
					},
					paint: {
						// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
						// with three steps to implement three types of circles:
						//   * Blue, 20px circles when point count is less than 100
						//   * Yellow, 30px circles when point count is between 100 and 750
						//   * Pink, 40px circles when point count is greater than or equal to 750
						"circle-color": [
							"step",
							["get", "point_count"],
							"#0f7501",
							100,
							"#f1f075",
							750,
							"#f28cb1",
						],
						"circle-radius": [
							"step",
							["get", "point_count"],
							20,
							100,
							30,
							750,
							40,
						],
						"circle-stroke-color": "#fff",
						"circle-stroke-width": [
							"case",
							["boolean", ["feature-state", "hover"], false],
							2,
							1,
						],
					},
				});

				//Add layer showing cluster count number
				map.current.addLayer({
					id: "cluster-count",
					type: "symbol",
					source: pointsId,
					filter: ["has", "point_count"],
					layout: {
						// Make the layer visible by default.
						visibility: "visible",
						"text-field": "{point_count_abbreviated}",
						"text-font": [
							"DIN Offc Pro Medium",
							"Arial Unicode MS Bold",
						],
						"text-size": 12,
					},
					paint: { "text-color": "#fff" },
				});
			});
		}
	} else {
		if (
			map.current.getSource(pointsId) &&
			map.current.getLayer(pointsId) &&
			map.current.getLayer("clusters") &&
			map.current.getLayer("cluster-count")
		) {
			//remove all layers of points if allready exist
			map.current.removeLayer("cluster-count");
			map.current.removeLayer("clusters");
			map.current.removeLayer(pointsId);
			map.current.removeSource(pointsId);
		}
	}
};

export default addSourceAndLayer;
