import { pointsId, zonesId } from "../constants";

/**
 * handle when mouse enter a zone on map
 * @param {*} currentElement current elemnt entered
 * @param {*} map current map
 * @param {*} handlePoint callback to set current point info
 * @returns
 */
const onEnterPoint = (currentElement, map, setPointHovered) => (e) => {
	map.current.getCanvas().style.cursor = "pointer";
	const point = e.features[0];
	if (!point.properties.cluster) {
		setPointHovered({ ...point.properties });
		// Check whether features exist
		if (e.features.length > 0) {
			//reset previous hovered element
			if (currentElement) {
				map.current.setFeatureState(
					{
						source: zonesId,
						id: currentElement,
					},
					{ hover: false }
				);
				map.current.setFeatureState(
					{
						source: pointsId,
						id: currentElement,
					},
					{ hover: false }
				);
			}

			// When the mouse moves over the layer, update the
			// feature state for the feature under the mouse
			map.current.setFeatureState(
				{
					source: pointsId,
					id: point.id,
				},
				{
					hover: true,
				}
			);
		}
	}
};

export default onEnterPoint;
