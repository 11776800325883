import {
	Button,
	Container,
	Grid,
	makeStyles,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { UserSelector } from "../../selectors";
import isUserProfessionnal from "../../utils/isUserProfessionnal";
import requestOwnershipAPI from "./services/requestOwnershipAPI";

const useStyle = makeStyles((theme) => ({
	root: {
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(6),
	},
	button: { marginLeft: theme.spacing(1), marginRight: theme.spacing(1) },
	notFound: { textAlign: "center", fontStyle: "italic" },
}));

/**
 * Form for user to ask for zone ownership
 * @returns
 */
function ZoneOwnerRequest() {
	const classes = useStyle();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	//zone name in the URL
	const { name } = useParams();

	//current user connected
	const user = useSelector(UserSelector);

	//user comment
	const [comment, setComment] = useState("");

	//change comment state
	const handleCommentChange = (event) => {
		setComment(event.target.value);
	};

	//send ownership request
	const onSend = () => {
		requestOwnershipAPI(user.token, name, comment).then(({ message }) => {
			enqueueSnackbar(message, {
				variant: "success",
			});
			history.replace("/");
		});
	};

	return (
		<Container className={classes.root}>
			<Paper className={classes.paper} elevation={3}>
				{isUserProfessionnal(user.roles) ? (
					<Grid container spacing={3}>
						<Grid item xs={12} align="center">
							<Typography variant="h6">
								Demande d'inscription sur le quartier "{name}"
							</Typography>
						</Grid>
						<Grid item xs={12} align="center">
							<TextField
								fullWidth
								id="outlined-multiline-static"
								label="(Optionnel) Message à l'administration"
								multiline
								rows={4}
								value={comment}
								onChange={(e) => handleCommentChange(e)}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} align="center">
							<Button
								className={classes.button}
								variant="contained"
								onClick={() => history.replace("/")}
								color="primary"
							>
								Annuler
							</Button>
							<Button
								className={classes.button}
								variant="contained"
								onClick={() => {
									onSend();
								}}
								color="primary"
							>
								Envoyer
							</Button>
						</Grid>
					</Grid>
				) : (
					<Typography
						variant="h5"
						component="h5"
						className={classes.notFound}
					>
						Page réservée aux bergers et aux éleveurs
					</Typography>
				)}
			</Paper>
		</Container>
	);
}

export default ZoneOwnerRequest;
