import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	makeStyles,
} from "@material-ui/core";
import { EditLocationRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

const useStyle = makeStyles((theme) => ({
	replace: { marginBottom: theme.spacing(4) },
}));

/**
 * Dialog for new point creation
 * @returns
 */
function NewPointDialog({ open, onClose, onCancel, onSubmit }) {
	const classes = useStyle();
	const { enqueueSnackbar } = useSnackbar();

	//values for the point properties
	const [allValues, setAllValues] = useState({
		CPTChecked: false,
		herdChecked: false,
		herdCrossingChecked: false,
	});

	//handle values change
	const changeHandler = (e) => {
		if (e.target.name === "herdChecked") {
			setAllValues({
				...allValues,
				[e.target.name]: e.target.checked,
				herdCrossingChecked: false,
			});
		} else {
			setAllValues({ ...allValues, [e.target.name]: e.target.checked });
		}
	};

	return (
		<Dialog
			open={open}
			onClose={(event, reason) => {
				if (reason !== "backdropClick") {
					onClose();
				}
			}}
			aria-labelledby="form-dialog-title"
			disableEscapeKeyDown
		>
			<DialogTitle id="form-dialog-title" align="center">
				Nouvel événement
			</DialogTitle>
			<DialogContent>
				<Grid container direction="column" alignItems="center">
					<Grid item xs className={classes.replace}>
						<Button
							variant="outlined"
							onClick={() => {
								onClose();
								enqueueSnackbar(
									"Attrapez le point pour le déplacer",
									{
										variant: "info",
									}
								);
							}}
							startIcon={<EditLocationRounded />}
							align="center"
						>
							Replacer le point
						</Button>
					</Grid>
					<Grid item xs>
						<FormControl component="fieldset">
							<FormLabel component="legend">
								Choisissez une ou plusieurs raisons
							</FormLabel>
							<FormControlLabel
								control={
									<Checkbox
										checked={allValues.CPTChecked}
										onChange={changeHandler}
										name="CPTChecked"
										color="primary"
									/>
								}
								label="Chien(s) de protection présent(s)"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={allValues.herdChecked}
										onChange={changeHandler}
										name="herdChecked"
										color="primary"
									/>
								}
								label="Troupeau présent"
							/>
							<FormControlLabel
								disabled={!allValues.herdChecked}
								control={
									<Checkbox
										checked={
											allValues.herdChecked &&
											allValues.herdCrossingChecked
										}
										onChange={changeHandler}
										name="herdCrossingChecked"
										color="primary"
									/>
								}
								label="Le troupeau est à proximité d'un passage"
							/>
						</FormControl>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel}>Annuler</Button>
				<Button
					onClick={() => {
						if (
							allValues.CPTChecked ||
							allValues.herdChecked ||
							allValues.herdCrossingChecked
						) {
							onSubmit(allValues);
						} else {
							enqueueSnackbar(
								"Vous devez cocher au moins une case",
								{
									variant: "error",
								}
							);
						}
					}}
					color="primary"
					variant="contained"
				>
					Ajouter
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default NewPointDialog;
