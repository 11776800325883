import Cookies from "js-cookie";
import { SET_WELCOME_STATUS } from "../constants/ActionsType";

//initial state of the welcome dialog
const initialState = {
	checked: Cookies.get("welcome") || false,
};

//Reducer for the welcome dialog
const WelcomeReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_WELCOME_STATUS:
			Cookies.set("welcome", action.payload.checked, { expires: 365 });
			return { ...state, checked: action.payload.checked };

		default:
			return state;
	}
};

export default WelcomeReducer;
