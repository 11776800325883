import { Divider, Grid } from "@material-ui/core";
import React from "react";
import AccountDeletionRequestContainer from "./containers/AccountDeletionRequestContainer";
import AccountMailContainer from "./containers/AccountMailContainer";
import AccountPasswordContainer from "./containers/AccountPasswordContainer";

/**
 * Component for the User account parameter
 * @returns
 */
function Account() {
	return (
		<>
			<Grid item xs>
				<AccountMailContainer />
			</Grid>
			<Divider variant="middle" />
			<Grid item xs>
				<AccountPasswordContainer />
			</Grid>
			<Divider variant="middle" />
			<Grid item xs>
				<AccountDeletionRequestContainer />
			</Grid>
		</>
	);
}

export default Account;
