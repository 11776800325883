import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { WelcomeSelector } from "../../selectors";
import HomepageMap from "./components/HomepageMap";
import WelcomeDialog from "./components/WelcomeDialog";

/**
 * Main component of the Homepage
 * @returns
 */
function Homepage() {
	const [open, setOpen] = useState(false);
	const welcome = useSelector(WelcomeSelector);

	//if first visit on the site show welcome dialog
	useEffect(() => {
		if (!welcome.checked) {
			setOpen(true);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<HomepageMap />
			<WelcomeDialog open={open} onClose={() => setOpen(false)} />
		</div>
	);
}

export default Homepage;
