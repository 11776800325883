import { Grid, Hidden, makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
	info: {
		backgroundColor: "rgba(35, 55, 75, 0.9)",
		color: "#ffffff",
		padding: "6px 12px",
		zIndex: 1,
		margin: "12px",
		borderRadius: "4px",
	},
}));

/**
 * Component showing zone info
 * @returns
 */
function ZoneInfo({ zone }) {
	const classes = useStyle();
	return (
		<div>
			{zone && (
				<Hidden smDown>
					<Grid className={classes.info} container direction="column">
						<Grid item>
							<strong>Nom:</strong> <span>{zone.name}</span>
						</Grid>
						<Grid item>
							<strong>Présence sur le quartier:</strong>{" "}
							<span>{zone.isPresent ? "Oui" : "Non"}</span>
						</Grid>
						{zone.chomePark && (
							<Grid item>
								<strong>Parc de Chome</strong>
								{zone.chomeCrossing && (
									<span>{" (Chome près d'un sentier)"}</span>
								)}
							</Grid>
						)}
					</Grid>
				</Hidden>
			)}
		</div>
	);
}

export default ZoneInfo;
