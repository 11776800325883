import { Grid, IconButton, makeStyles, Slide } from "@material-ui/core";
import { CloseRounded, DoneRounded, UndoRounded } from "@material-ui/icons";
import React from "react";
import LightTooltip from "../../../components/LightToolTips";

const useStyle = makeStyles((theme) => ({
	infoLike: {
		backgroundColor: "rgba(35, 55, 75, 0.9)",
		color: "#ffffff",
		padding: "6px 12px",
		zIndex: 1,
		borderRadius: "4px",
		position: "absolute",
		right: 12,
		bottom: 30,
		width: "250px",
		[theme.breakpoints.down("sm")]: {
			left: 12,
			bottom: 80,
			width: "auto",
		},
	},
	menuClose: { float: "right", padding: 0 },
}));

/**
 * Component to undo or submit new zone
 * @returns
 */
function NewZoneInfo({
	addZone,
	handleZoneUndo,
	handleZoneCancel,
	handleZoneSubmit,
}) {
	const classes = useStyle();
	return (
		<Slide direction="up" in={addZone} mountOnEnter unmountOnExit>
			<Grid className={classes.infoLike} container direction="column">
				<Grid item xs align="center">
					<strong>Nouveau quartier</strong>
				</Grid>
				<Grid item xs align="center">
					<LightTooltip title="Annuler la création">
						<IconButton
							onClick={() => {
								handleZoneCancel();
							}}
						>
							<CloseRounded color="secondary" />
						</IconButton>
					</LightTooltip>
					<LightTooltip title="Revenir en arrière">
						<IconButton
							onClick={() => {
								handleZoneUndo();
							}}
						>
							<UndoRounded color="secondary" />
						</IconButton>
					</LightTooltip>

					<LightTooltip title="Fermer le polygone et créer le quartier">
						<IconButton
							onClick={() => {
								handleZoneSubmit();
							}}
						>
							<DoneRounded color="secondary" />
						</IconButton>
					</LightTooltip>
				</Grid>
			</Grid>
		</Slide>
	);
}

export default NewZoneInfo;
