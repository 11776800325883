import { Container, Grid, Hidden, makeStyles } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import MySpaceContent from "./components/MySpaceContent";
import MySpaceMenu from "./components/MySpaceMenu";

const useStyle = makeStyles((theme) => ({
	root: {
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}));

/**
 * Component for the User account parameter page
 * @returns
 */
function MySpace() {
	const classes = useStyle();

	//get id of page from the url
	const { id } = useParams();
	return (
		<Container className={classes.root}>
			<Grid container spacing={3}>
				<Hidden smDown>
					<Grid item md={3}>
						<MySpaceMenu id={id} />
					</Grid>
				</Hidden>
				<Grid item md={9} xs>
					<MySpaceContent id={id} />
				</Grid>
			</Grid>
		</Container>
	);
}

export default MySpace;
