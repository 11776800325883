import { Divider, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import ProfileImageContainer from "./containers/ProfileImageContainer.js";
import ProfileInformationsContainer from "./containers/ProfileInformationsContainer";

const useStyle = makeStyles((theme) => ({
	divider: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
}));

/**
 * Component for the User profile parameter
 * @returns
 */
function Profile() {
	const classes = useStyle();
	return (
		<>
			<Grid item xs>
				<ProfileInformationsContainer />
			</Grid>
			<Divider className={classes.divider} />
			<Grid item xs>
				<ProfileImageContainer />
			</Grid>
		</>
	);
}

export default Profile;
