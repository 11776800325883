import { useSnackbar } from "notistack";
import React from "react";
import ZoneAdminDialog from "../components/ZoneAdminDialog";
import disableZoneActivityAPI from "../services/disableZoneActivityAPI";
import setZonePropertiesAPI from "../services/setZonePropertiesAPI";

function ZoneAdminDialogContainer({
	user,
	currentZone,
	users,
	open,
	handleDialog,
}) {
	const { enqueueSnackbar } = useSnackbar();

	//submit new zone properties
	const onSubmit = (properties) => {
		setZonePropertiesAPI(user.token, currentZone.id, properties).then(
			() => {
				handleDialog();
			}
		);
	};

	//disabled zone activity
	const onDisabled = (zoneId) => {
		disableZoneActivityAPI(user, zoneId)
			.then(({ message }) => {
				handleDialog();
				enqueueSnackbar(message, {
					variant: "success",
				});
			})
			.catch((err) => {
				enqueueSnackbar(err, {
					variant: "error",
				});
			});
	};

	return (
		<ZoneAdminDialog
			zone={currentZone}
			users={users}
			open={open}
			handleDialog={handleDialog}
			handleSubmit={onSubmit}
			onDisabled={onDisabled}
		/>
	);
}

export default ZoneAdminDialogContainer;
