//geolocate control component
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

export const geolocate = new mapboxgl.GeolocateControl({
	positionOptions: {
		enableHighAccuracy: true,
	},
	trackUserLocation: true,
	showUserHeading: true,
});

//id for zone source map
export const zonesId = "zones_list";

//id for point source map
export const pointsId = "points_list";

//Initial position on map (Chamonix Mont-Blanc)
export const initialPosition = { lng: 6.8723, lat: 45.9242, zoom: 13 };

export const initialPointClicked = {
	clicked: false,
	id: null,
	properties: {},
};
