import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import ReactPlayer from "react-player";

const useStyle = makeStyles((theme) => ({
	player: { maxWidth: "480px", maxHeight: "270px" },
}));

function DogBehaviourInfo() {
	const classes = useStyle();
	return (
		<Grid container spacing={5}>
			<Grid item xs={12} align="center">
				<Typography variant="h3" color="primary">
					Comment se comporter face à un troupeau et ses CPT
				</Typography>
			</Grid>
			<Grid item xs={12} align="center">
				<Typography variant="h6">
					Chiens de protection et randonneurs : besoin d'un décodeur ?
				</Typography>
				<ReactPlayer
					className={classes.player}
					url="https://www.youtube.com/watch?v=_gn8HVEcYyc"
					width="100%"
				/>
			</Grid>
			<Grid item xs={12} align="center">
				<Typography variant="h6">
					Les chiens de protection des troupeaux : qui sont-ils ?
				</Typography>
				<ReactPlayer
					className={classes.player}
					url="https://www.youtube.com/watch?v=rqIzaRO-__s"
					width="100%"
				/>
			</Grid>
			<Grid item xs={12} align="center">
				<Typography variant="h6">
					Les chiens de protection des troupeaux et activités
					sportives
				</Typography>
				<ReactPlayer
					className={classes.player}
					url="https://www.youtube.com/watch?v=Q_NseI2KERk"
					width="100%"
				/>
			</Grid>
		</Grid>
	);
}

export default DogBehaviourInfo;
