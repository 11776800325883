import {
	AppBar,
	Button,
	Chip,
	Divider,
	Grid,
	IconButton,
	makeStyles,
	Slide,
	Toolbar,
	Typography,
	useScrollTrigger,
} from "@material-ui/core";
import {
	AccountCircleRounded,
	HomeRounded,
	InfoRounded,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UserSelector } from "../../selectors";
import isUserConnected from "../../utils/isUserConnected";
import LightTooltip from "../LightToolTips";
import SignInDrawer from "../SignIn/SignInDrawer";
import UserMenuDrawer from "../UserMenu/UserMenuDrawer";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		height: theme.mixins.toolbar.minHeight,
		minHeight: 0,
		zIndex: theme.zIndex.drawer + 1000,
		background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
	},
	button: {
		color: theme.palette.secondary.light,
	},
	textColor: {
		color: theme.palette.secondary.light,
		textDecoration: "none",
	},
	divider: { marginLeft: theme.spacing(1), marginRight: theme.spacing(2) },
	beta: {
		marginLeft: theme.spacing(2),
		color: "#D85757",
		borderColor: "#D85757",
	},
	cursor: { cursor: "pointer" },
}));

//function to hide appbar on scroll down
function HideOnScroll(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({ target: window ? window() : undefined });

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

/**
 * Component for desktop appbar
 * @returns
 */
function TopBar(props) {
	const classes = useStyles();

	//current user connected
	const user = useSelector(UserSelector);

	//state of connection drawer
	const [openConnect, setOpenConnect] = useState(false);

	//state of menu drawer
	const [openMenu, setOpenMenu] = useState(false);

	//toggle open menu drawer
	const toggleDrawer = () => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		if (!isUserConnected(user)) {
			setOpenConnect(!openConnect);
		} else {
			setOpenMenu(!openMenu);
		}
	};

	//toggle close menu drawer
	const closeDrawer = () => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		if (openConnect || openMenu) {
			if (!isUserConnected(user)) {
				setOpenConnect(!openConnect);
			} else {
				setOpenMenu(!openMenu);
			}
		}
	};

	//select appbar content depending on user
	const topBarComponent = () => {
		if (!isUserConnected(user)) {
			return (
				<>
					<Grid item>
						<Button
							variant="outlined"
							component={Link}
							to="/sign-up"
							style={{ margin: "8px" }}
							onClick={closeDrawer()}
							className={classes.button}
						>
							Inscription
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant="outlined"
							onClick={toggleDrawer()}
							className={classes.button}
						>
							Connexion
						</Button>
					</Grid>
				</>
			);
		} else {
			return (
				<>
					<Divider
						className={classes.divider}
						orientation="vertical"
					/>
					<Grid item>
						<Typography
							variant="h6"
							className={clsx(classes.textColor, classes.cursor)}
							onClick={toggleDrawer()}
							style={{ marginRight: "8px" }}
						>
							{user.data.firstname}
						</Typography>
					</Grid>
					<Grid item>
						<IconButton
							edge="start"
							className={classes.textColor}
							onClick={toggleDrawer()}
						>
							<AccountCircleRounded />
						</IconButton>
					</Grid>
				</>
			);
		}
	};

	return (
		<div className={classes.root}>
			<HideOnScroll {...props}>
				<AppBar className={classes.root}>
					<Toolbar className={classes.root}>
						<Grid container>
							<Grid container item xs alignItems="center">
								<Grid item>
									<IconButton
										component={Link}
										to="/"
										edge="start"
										onClick={closeDrawer()}
										className={classes.textColor}
									>
										<HomeRounded />
									</IconButton>
								</Grid>
								<Grid item>
									<Typography
										component={Link}
										to="/"
										variant="h6"
										onClick={closeDrawer()}
										className={classes.textColor}
									>
										Patoutéou
									</Typography>
								</Grid>
								<Grid item>
									<Chip
										className={classes.beta}
										variant="outlined"
										label="Beta"
									/>
								</Grid>
							</Grid>
							<Grid
								container
								item
								xs
								alignItems="center"
								justifyContent="flex-end"
							>
								<Grid item>
									<LightTooltip title="Informations de prévention">
										<IconButton
											component={Link}
											to={"/informations"}
											className={classes.textColor}
											onClick={closeDrawer()}
										>
											<InfoRounded />
										</IconButton>
									</LightTooltip>
								</Grid>
								{topBarComponent()}
							</Grid>
						</Grid>
					</Toolbar>
					<SignInDrawer open={openConnect} onClose={toggleDrawer()} />
					<UserMenuDrawer open={openMenu} onClose={toggleDrawer()} />
				</AppBar>
			</HideOnScroll>
		</div>
	);
}

export default TopBar;
