import Cookies from "js-cookie";
import { SET_DARK_MODE } from "../constants/ActionsType";

//initial state of the app style
const initialState = {
	dark: Cookies.get("dark") === "true" || false,
};

//Reducer for the app style
const StyleReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_DARK_MODE:
			Cookies.set("dark", !state.dark, { expires: 365 });
			return { ...state, dark: !state.dark };

		default:
			return state;
	}
};

export default StyleReducer;
