import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInformation } from "../../../../actions";
import { UserSelector } from "../../../../selectors";
import ProfessionalProfileInformations from "../components/ProfessionalProfileInformations";
import modifyProfessionalInformationsAPI from "../services/modifyProfessionalInformationsAPI";

/**
 * Container to display and modify professional informations of user
 * @returns
 */
function ProfessionalProfileInformationsContainer() {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	// get user from the store
	const user = useSelector(UserSelector);

	// When user change his informations
	const onSubmit = (allValues) => {
		// call the service
		if (
			allValues.numberCPT !== null &&
			allValues.numberMale !== null &&
			allValues.numberFemale !== null
		) {
			modifyProfessionalInformationsAPI(user, allValues).then(
				({ user, message }) => {
					dispatch(setUserInformation(user));
					enqueueSnackbar(message, {
						variant: "success",
					});
				}
			);
		} else {
			enqueueSnackbar("Merci de remplir tous les champs", {
				variant: "error",
			});
		}
	};

	return (
		<ProfessionalProfileInformations user={user.data} onSubmit={onSubmit} />
	);
}

export default ProfessionalProfileInformationsContainer;
