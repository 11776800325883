import { useSnackbar } from "notistack";
import React from "react";
import { useSelector } from "react-redux";
import { UserSelector } from "../../../../selectors";
import AccountDeletionRequest from "../components/AccountDeletionRequest";
import deleteAccountRequestAPI from "../services/deleteAccountRequestAPI";

function AccountDeletionRequestContainer() {
	// get the user from the store
	const connectedUser = useSelector(UserSelector);

	// to generate a popup
	const { enqueueSnackbar } = useSnackbar();

	// when user delete his account
	const onSubmit = () => {
		// call the service
		deleteAccountRequestAPI(connectedUser)
			.then(({ message }) => {
				enqueueSnackbar(message, {
					variant: "info",
				});
			})
			.catch((err) => {
				enqueueSnackbar(err, {
					variant: "error",
				});
			});
	};

	return <AccountDeletionRequest onSubmit={onSubmit} />;
}

export default AccountDeletionRequestContainer;
