import {
	Button,
	Divider,
	Drawer,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	makeStyles,
} from "@material-ui/core";
import { ClearRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserDisconnected } from "../../actions";
import { UserSelector } from "../../selectors";
import isUserAdmin from "../../utils/isUserAdmin";
import isUserProfessionnal from "../../utils/isUserProfessionnal";
import UserMenuHeader from "./UserMenuHeader";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "350px",
		marginTop: theme.mixins.toolbar.minHeight,
		[theme.breakpoints.down("sm")]: {
			marginBottom: theme.mixins.toolbar.minHeight,
			marginTop: 0,
			width: "100vw",
		},
	},
	header: { marginBottom: theme.spacing(3) },
	menuClose: { float: "right" },
	button: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
}));

/**
 * Component for the user's menu drawer
 * @param open state of the drawer
 * @param onClose function to close the drawer
 * @returns
 */
function UserMenuDrawer({ open, onClose }) {
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	//current user connected
	const user = useSelector(UserSelector);

	//function that disconnect the current user
	const onDisconnect = (event) => {
		dispatch(setUserDisconnected());
		history.replace("/");
		enqueueSnackbar(`Vous êtes déconnecté`, {
			variant: "info",
		});
		onClose(event);
	};

	return (
		<Drawer anchor={"right"} open={open} onClose={onClose}>
			<Grid className={classes.root} container direction="column">
				<Grid item xs>
					<div className={classes.menuClose}>
						<IconButton onClick={onClose}>
							<ClearRounded color="secondary" />
						</IconButton>
					</div>
				</Grid>
				<Grid className={classes.header} item xs align="center">
					<UserMenuHeader onClose={onClose} />
				</Grid>

				{user.roles && isUserProfessionnal(user.roles) && (
					<>
						<Divider className={classes.divider} />
						<Grid item xs>
							<List>
								<ListItem
									button
									onClick={(event) => {
										history.push("/my-space/activity");
										onClose(event);
									}}
								>
									<ListItemText align="right">
										Mon activité
									</ListItemText>
								</ListItem>
							</List>
						</Grid>
					</>
				)}
				<Divider className={classes.divider} />
				<Grid item xs>
					<List>
						<ListItem
							button
							onClick={(event) => {
								history.push("/my-space/profile");
								onClose(event);
							}}
						>
							<ListItemText align="right">
								Mon Profil
							</ListItemText>
						</ListItem>
						<ListItem
							button
							onClick={(event) => {
								history.push("/my-space/account");
								onClose(event);
							}}
						>
							<ListItemText align="right">
								Mon compte
							</ListItemText>
						</ListItem>
						<ListItem
							button
							onClick={(event) => {
								history.push("/my-space/settings");
								onClose(event);
							}}
						>
							<ListItemText align="right">
								Paramètres
							</ListItemText>
						</ListItem>
					</List>
				</Grid>
				{user.roles && isUserAdmin(user.roles) && (
					<>
						<Divider className={classes.divider} />
						<Grid item xs>
							<List>
								<ListItem
									button
									onClick={(event) => {
										history.push("/my-space/admin");
										onClose(event);
									}}
								>
									<ListItemText align="right">
										Administration
									</ListItemText>
								</ListItem>
							</List>
						</Grid>
					</>
				)}
				<Divider className={classes.divider} />
				<Grid item xs align="center">
					<Button
						className={classes.button}
						variant="contained"
						color="primary"
						onClick={onDisconnect}
					>
						Déconnexion
					</Button>
				</Grid>
			</Grid>
		</Drawer>
	);
}
export default UserMenuDrawer;
