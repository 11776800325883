import { Grid, IconButton, makeStyles, Slide } from "@material-ui/core";
import {
	ClearRounded,
	ThumbDownRounded,
	ThumbUpRounded,
} from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { setUserLikes } from "../../../actions";
import isToday from "../../../utils/isToday";
import isUserConnected from "../../../utils/isUserConnected";
import { initialPointClicked } from "../constants";
import changePointUserCount from "../services/changePointUserCount";

const useStyle = makeStyles((theme) => ({
	infoLike: {
		backgroundColor: "rgba(35, 55, 75, 0.9)",
		color: "#ffffff",
		padding: "6px 12px",
		zIndex: 1,
		borderRadius: "4px",
		position: "absolute",
		right: 12,
		bottom: 30,
		width: "250px",
		[theme.breakpoints.down("sm")]: {
			left: 12,
			bottom: 80,
			width: "auto",
		},
	},
	menuClose: { float: "right", padding: 0 },
}));

/**
 * Component to like or not the point
 * @returns
 */
function LikeInfo({ user, point, onClose }) {
	const classes = useStyle();
	const dispatch = useDispatch();

	//check if point already liked
	const alreadyliked =
		user.likes && user.likes.find((like) => like === point.id);

	//change point score
	const handleUserCount = (count) => {
		changePointUserCount(user.token, point.id, count).then(({ likes }) => {
			dispatch(setUserLikes(likes));
			onClose(initialPointClicked);
		});
	};

	return (
		<Slide
			direction="up"
			in={
				point.clicked &&
				!alreadyliked &&
				isToday(new Date(point.properties.createdAt)) &&
				isUserConnected(user) &&
				user.data.id !== point.properties.createdBy
			}
			mountOnEnter
			unmountOnExit
		>
			<Grid className={classes.infoLike} container direction="column">
				<Grid item xs>
					<IconButton
						onClick={onClose}
						className={classes.menuClose}
						size="small"
					>
						<ClearRounded color="secondary" />
					</IconButton>
				</Grid>
				<Grid item xs align="center">
					<strong>Valider l'événement ?</strong>
				</Grid>
				<Grid item xs align="center">
					<IconButton onClick={() => handleUserCount(true)}>
						<ThumbUpRounded color="secondary" />
					</IconButton>
					<IconButton onClick={() => handleUserCount(false)}>
						<ThumbDownRounded color="secondary" />
					</IconButton>
				</Grid>
			</Grid>
		</Slide>
	);
}

export default LikeInfo;
