import { Grid, Hidden, makeStyles } from "@material-ui/core";
import React from "react";
import createdAtToText from "../../../utils/createdAtToText";
import isToday from "../../../utils/isToday";

const useStyle = makeStyles((theme) => ({
	info: {
		backgroundColor: "rgba(35, 55, 75, 0.9)",
		color: "#ffffff",
		padding: "6px 12px",
		zIndex: 1,
		margin: "12px",
		borderRadius: "4px",
	},
}));

/**
 * Component showing point info
 * @returns
 */
function PointInfo({ point }) {
	const classes = useStyle();
	return (
		<div>
			{point && (
				<Hidden smDown>
					<Grid className={classes.info} container direction="column">
						<Grid item>
							<strong>Heure:</strong>{" "}
							<span>
								{`${
									(
										new Date(point.createdAt).getHours() - 2
									).toString().length === 1
										? "0" +
										  (new Date(
												point.createdAt
										  ).getHours() -
												2)
										: new Date(point.createdAt).getHours() -
										  2
								} H 
					${
						new Date(point.createdAt).getMinutes().toString()
							.length === 1
							? "0" + new Date(point.createdAt).getMinutes()
							: new Date(point.createdAt).getMinutes()
					}`}
							</span>
						</Grid>
						<Grid item>
							<strong>Date:</strong>{" "}
							<span>
								{`${
									isToday(new Date(point.createdAt))
										? ""
										: new Date(point.createdAt).getDate()
								} 
				${
					isToday(new Date(point.createdAt))
						? "Aujourd'hui"
						: createdAtToText(point.createdAt)
				}`}
							</span>
						</Grid>

						<Grid item>
							<strong>Evénements:</strong>{" "}
							<span>
								{point.CPTChecked && "CPT présent(s)"}
								{point.CPTChecked && point.herdChecked && ", "}
								{point.herdChecked && "Troupeau(x) présent(s)"}
								{(point.CPTChecked || point.herdChecked) &&
									point.herdCrossingChecked &&
									", "}
								{point.herdCrossingChecked &&
									"Troupeau(x) à proximité d'un passage"}
							</span>
						</Grid>
					</Grid>
				</Hidden>
			)}
		</div>
	);
}

export default PointInfo;
