const addSourceAndLayerDrawZone = (map, zoneMarkers) => {
	if (
		!map.current.getSource("zoneMarkers") &&
		!map.current.getLayer("measure-points") &&
		!map.current.getLayer("measure-lines")
	) {
		map.current.addSource("zoneMarkers", {
			type: "geojson",
			data: zoneMarkers,
		});

		// Add styles to the map
		map.current.addLayer({
			id: "measure-points",
			type: "circle",
			source: "zoneMarkers",
			paint: {
				"circle-radius": 5,
				"circle-color": "#505050",
			},
			filter: ["in", "$type", "Point"],
		});
		map.current.addLayer({
			id: "measure-lines",
			type: "line",
			source: "zoneMarkers",
			layout: {
				"line-cap": "round",
				"line-join": "round",
			},
			paint: {
				"line-color": "#505050",
				"line-width": 2.5,
			},
			filter: ["in", "$type", "LineString"],
		});
	}
};

export default addSourceAndLayerDrawZone;
