import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	Grid,
	IconButton,
	makeStyles,
} from "@material-ui/core";
import {
	AddLocationRounded,
	CropLandscapeRounded,
	EditRounded,
	RadioButtonCheckedRounded,
	RoundedCornerSharp,
	ThreeDRotationRounded,
} from "@material-ui/icons";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import LightTooltip from "../../../components/LightToolTips";
import isUserAdmin from "../../../utils/isUserAdmin";
import isUserProfessionnal from "../../../utils/isUserProfessionnal";
import { pointsId, zonesId } from "../constants";

const SATELLITE_VIEW = process.env.PUBLIC_URL + "/img/satellite_view.jpg";
const TERRAIN_VIEW = process.env.PUBLIC_URL + "/img/terrain_view.jpg";

const useStyle = makeStyles((theme) => ({
	root: {
		width: "30px",
		zIndex: 1,
		position: "absolute",
		top: theme.spacing(23) + theme.mixins.toolbar.minHeight,
		right: "10px",
		borderRadius: "3px",
		[theme.breakpoints.down("sm")]: {
			top: theme.spacing(23),
		},
		[`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
			left: "10px",
			top: "10px",
		},
	},
	summary: { minHeight: 0, padding: 0 },
	detail: { width: "30px", padding: 0 },
	button: {
		width: "30px",
		height: "30px",
		padding: 0,
		margin: 0,
	},
	image: {
		marginTop: "3px",
		marginBottom: 0,
		width: "26px",
		height: "26px",
		borderRadius: "5px",
		border: "1px solid grey",
		cursor: "pointer",
	},
	green: { color: "green" },
	orange: { color: "orange" },
	disabled: { color: "grey" },
}));

/**
 * Component for the sidebar user actions on the map (add Marker , 3D map , change layer)
 * @returns
 */
function SideBarAction({
	user,
	addMarker,
	setAddMarker,
	addZone,
	setAddZone,
	mapWithRelief,
	handleMapWithRelief,
	satelliteMap,
	handleSatelliteMap,
	map,
}) {
	const classes = useStyle();
	const { enqueueSnackbar } = useSnackbar();

	//state of the zone layer visibility
	const [zoneVisibility, setZoneVisibility] = useState("visible");

	//state of the point layer visibility
	const [pointVisibility, setPointVisibility] = useState("visible");

	//handle action to hide layers
	const handleHidden = (clickedLayer) => {
		if (map.current) {
			const clustersId = ["clusters", "cluster-count"];

			//get chosen layer visibility
			const visibility = map.current.getLayoutProperty(
				clickedLayer,
				"visibility"
			);

			//create new visibilty
			const newVisibility = visibility === "visible" ? "none" : "visible";

			// Toggle layer visibility by changing the layout object's visibility property.
			map.current.setLayoutProperty(
				clickedLayer,
				"visibility",
				newVisibility
			);

			//Toggle relative layers visibility
			if (clickedLayer === zonesId) {
				setZoneVisibility(newVisibility);
				map.current.setLayoutProperty(
					"zoneLines",
					"visibility",
					newVisibility
				);
			} else if (clickedLayer === pointsId) {
				setPointVisibility(newVisibility);
				for (const cluster of clustersId) {
					map.current.setLayoutProperty(
						cluster,
						"visibility",
						newVisibility
					);
				}
			}
		}
	};

	return (
		<Accordion className={classes.root}>
			<AccordionSummary
				className={classes.summary}
				expandIcon={<EditRounded />}
				IconButtonProps={{ className: classes.button }}
				aria-controls="panel1a-content"
				id="panel1a-header"
			></AccordionSummary>
			<AccordionDetails className={classes.detail}>
				<Grid container direction="column">
					<Divider />
					<Grid item xs>
						<LightTooltip title="Ajouter un événement">
							<IconButton
								disabled={addMarker || addZone}
								className={classes.button}
								color="primary"
								onClick={() => {
									setAddMarker(!addMarker);
									enqueueSnackbar(
										"Placez un point sur la carte",
										{
											variant: "info",
										}
									);
								}}
							>
								<AddLocationRounded />
							</IconButton>
						</LightTooltip>
					</Grid>
					{(isUserProfessionnal(user.roles) ||
						isUserAdmin(user.roles)) && (
						<Grid item xs>
							<LightTooltip title="Ajouter un quartier">
								<IconButton
									disabled={addMarker || addZone}
									className={classes.button}
									color="primary"
									onClick={() => {
										setAddZone(!addZone);
										enqueueSnackbar(
											"Cliquez sur la carte pour placer des points et tracer le quartier",
											{
												variant: "info",
											}
										);
									}}
								>
									<RoundedCornerSharp />
								</IconButton>
							</LightTooltip>
						</Grid>
					)}
					<Divider />
					<Grid item xs>
						<LightTooltip
							title={
								mapWithRelief
									? "Passer la carte en 2D"
									: "Passer la carte en 3D"
							}
						>
							<IconButton
								disabled={addMarker || addZone}
								className={classes.button}
								color="primary"
								onClick={() => {
									handleMapWithRelief(!mapWithRelief);
								}}
							>
								<ThreeDRotationRounded />
							</IconButton>
						</LightTooltip>
					</Grid>
					<Grid item xs align="center">
						<LightTooltip
							title={
								satelliteMap
									? "Passer en vue plan"
									: "Passer en vue satellite"
							}
						>
							<img
								className={classes.image}
								onClick={() => {
									if (!addMarker && !addZone) {
										handleSatelliteMap(!satelliteMap);
									}
								}}
								alt={satelliteMap ? "terrain" : "satellite"}
								src={
									satelliteMap ? TERRAIN_VIEW : SATELLITE_VIEW
								}
							/>
						</LightTooltip>
					</Grid>
					<Divider />
					<Grid item xs>
						<LightTooltip
							title={
								pointVisibility === "visible"
									? "Masquer les événements"
									: "Afficher les événements"
							}
						>
							<IconButton
								className={clsx(
									classes.button,
									pointVisibility === "visible"
										? classes.orange
										: classes.disabled
								)}
								onClick={() => {
									handleHidden(pointsId);
								}}
							>
								<RadioButtonCheckedRounded />
							</IconButton>
						</LightTooltip>
					</Grid>
					<Grid item xs>
						<LightTooltip
							title={
								zoneVisibility === "visible"
									? "Masquer les quartiers"
									: "Afficher les quartiers"
							}
						>
							<IconButton
								className={clsx(
									classes.button,
									zoneVisibility === "visible"
										? classes.green
										: classes.disabled
								)}
								onClick={(e) => {
									handleHidden(zonesId);
								}}
							>
								<CropLandscapeRounded />
							</IconButton>
						</LightTooltip>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
}

export default SideBarAction;
