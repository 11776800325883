import {
	Button,
	Checkbox,
	Chip,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import livestockTypeToText from "../../../../utils/livestockTypeToText";

const livestockList = [
	"suckling_sheep",
	"dairy_sheep",
	"goat",
	"suckling_cow",
	"dairy_cow",
	"heifer",
	"calf",
];

const useStyle = makeStyles((theme) => ({
	root: { marginBottom: theme.spacing(2), padding: theme.spacing(2) },
	title: {
		fontSize: "1.3em",
		fontWeight: "bold",
	},
	titleDisabled: {
		fontSize: "1.3em",
		fontWeight: "bold",
		color: "grey",
	},
	formLabel: { color: "grey" },
	success: { color: "green" },
}));

/**
 * Component for zone item informations
 * @returns
 */
function ZoneItem({ zone, onSubmit }) {
	const classes = useStyle();
	const { enqueueSnackbar } = useSnackbar();

	//current zone values
	const [allValues, setAllValues] = useState(zone.properties);

	//set zone values when loaded
	useEffect(() => {
		setAllValues(zone.properties);
	}, [zone]);

	//handle zone values change
	const changeHandler = (e) => {
		if (e.target.name === "livestockType") {
			setAllValues({ ...allValues, [e.target.name]: e.target.value });
		} else if (e.target.name === "chomePark") {
			setAllValues({
				...allValues,
				[e.target.name]: e.target.checked,
				chomeCrossing: false,
			});
		} else {
			setAllValues({ ...allValues, [e.target.name]: e.target.checked });
		}
	};

	return (
		<Paper className={classes.root} square elevation={3}>
			<Grid container spacing={3}>
				<Grid item container xs={12} alignItems="center" spacing={1}>
					<Grid item>
						<Typography
							className={
								zone.properties.isPresent ||
								zone.properties.chomePark
									? classes.title
									: classes.titleDisabled
							}
						>
							{zone.properties.name}{" "}
						</Typography>
					</Grid>
					<Grid item>
						{(zone.properties.isPresent ||
							zone.properties.chomePark) && (
							<Chip
								variant="outlined"
								label="Actif"
								className={clsx(classes.success, classes.title)}
							/>
						)}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Typography className={classes.formLabel}>
						Activité du quartier
					</Typography>
					<FormGroup row>
						<FormControlLabel
							className={
								zone.properties.isPresent ||
								zone.properties.chomePark
									? null
									: classes.titleDisabled
							}
							control={
								<Checkbox
									checked={allValues.isPresent}
									onChange={changeHandler}
									name="isPresent"
									color="primary"
								/>
							}
							label="Présence sur le quartier"
						/>
						<FormControlLabel
							className={
								zone.properties.isPresent ||
								zone.properties.chomePark
									? null
									: classes.titleDisabled
							}
							control={
								<Checkbox
									checked={allValues.chomePark}
									onChange={changeHandler}
									name="chomePark"
									color="primary"
								/>
							}
							label="Parc de Chome"
						/>
						<FormControlLabel
							className={
								zone.properties.isPresent ||
								zone.properties.chomePark
									? null
									: classes.titleDisabled
							}
							disabled={!allValues.chomePark}
							control={
								<Checkbox
									checked={
										allValues.chomePark &&
										allValues.chomeCrossing
									}
									onChange={changeHandler}
									name="chomeCrossing"
									color="primary"
								/>
							}
							label="Chome près d'un sentier"
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={12}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="demo-simple-select-outlined-label">
							Type de bétail
						</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							name="livestockType"
							value={allValues.livestockType || ""}
							onChange={changeHandler}
							label="Type de bétail"
						>
							<MenuItem value="">
								<em>Aucun</em>
							</MenuItem>
							{livestockList.map((livestock, index) => {
								return (
									<MenuItem value={livestock} key={index}>
										{livestockTypeToText(livestock)}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<FormControlLabel
						className={
							zone.properties.livestockType
								? null
								: classes.titleDisabled
						}
						disabled={!allValues.livestockType}
						control={
							<Checkbox
								checked={allValues.bullPresence || false}
								onChange={changeHandler}
								name="bullPresence"
								color="primary"
							/>
						}
						label="Taureau présent sur le quartier"
					/>
				</Grid>
				<Grid item xs={12} align="right">
					{zone.properties.isPresent || zone.properties.chomePark ? (
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								onSubmit(zone.id, {
									name: zone.properties.name,
									isPresent: false,
									chomeCrossing: false,
									chomePark: false,
									ownerId: zone.properties.ownerId,
								});
							}}
						>
							Désactiver
						</Button>
					) : (
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								if (
									allValues.isPresent ||
									allValues.chomePark
								) {
									onSubmit(zone.id, allValues);
								} else {
									enqueueSnackbar(
										"Sélectionnez au moins une case",
										{
											variant: "error",
										}
									);
								}
							}}
						>
							Activer
						</Button>
					)}
				</Grid>
			</Grid>
		</Paper>
	);
}

export default ZoneItem;
