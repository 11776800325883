import isUserProfessionnal from "../../../utils/isUserProfessionnal";
import livestockTypeToText from "../../../utils/livestockTypeToText";

/**
 * HTML content of the zone popup
 * @param {*} zone zone clicked
 * @param {*} user zone owner
 * @returns
 */
const zonePopupHTML = (zone, user, connectedUser) => {
	const isProfessionnal = isUserProfessionnal(connectedUser.roles);
	const livestockType = livestockTypeToText(zone.properties.livestockType);

	return `<div style="border:solid 1px;border-radius: 5px;">
				<p style="color:black;text-align:center">
					<span style="font-size: 1.2em;font-weight:bold">${zone.properties.name}</span>
					<br/> 
					${
						user
							? `${
									user.isActive
										? `	<span style="font-style:italic;color:black">Quartier de 
											<a href="/user/${zone.properties.ownerId}"  style="text-decoration:none;color:grey">
												${user.firstname}
											</a>
											</span> `
										: "<span style='font-style:italic;color:black'>Quartier non inscrit</span>"
							  }`
							: `${
									isProfessionnal
										? `<a href='/owner/request/${zone.properties.name}' style='text-decoration:none;color:grey;font-style:italic'>S'inscrire sur le quartier</a>`
										: "<span style='font-style:italic;color:black'>Quartier non inscrit</span>"
							  }`
					}
				</p>
				<hr class="dotted" style="margin-right:8px;margin-left:8px">
				<p style='color:black;margin:8px'>
					<strong>- Présence sur le quartier:</strong>
					${zone.properties.isPresent ? "Oui" : "Non"}
					${zone.properties.chomePark ? "<br/><strong>- Parc de Chome</strong>" : ""}
					${
						zone.properties.chomePark &&
						zone.properties.chomeCrossing
							? " (Chome près d'un sentier)"
							: ""
					}
					
					
					${
						zone.properties.livestockType
							? `<br/><strong>- Type de bétail: </strong> ${livestockType}`
							: ""
					}
					${
						zone.properties.livestockType &&
						zone.properties.bullPresence
							? " (Taureau présent)"
							: ""
					}
			
					<p style="text-align:center" >
							<a href="/informations/${3}" style="text-decoration:none;color:grey;font-style:italic">
								En savoir plus
							</a>
						</p>
				</p>
			</div>`;
};

export default zonePopupHTML;
