import {
	Button,
	FormControlLabel,
	FormLabel,
	Grid,
	makeStyles,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { useState } from "react";
import maxBirthDate from "../../../../utils/maxBirthDate";

const useStyle = makeStyles((theme) => ({
	textInput: {
		width: "100%",
	},
}));

/**
 * Component to display and modify informations of user
 * @param user current user
 * @param onSubmit callback to call when user modify his informations
 * @returns
 */
function ProfileInformations({ user, onSubmit }) {
	const classes = useStyle();

	//user values
	const [allValues, setAllValues] = useState({
		gender: user.gender,
		birthDate: user.birthDate,
		lastname: user.lastname,
		firstname: user.firstname,
	});

	//handle value change
	const changeHandler = (e) => {
		setAllValues({ ...allValues, [e.target.name]: e.target.value });
	};

	//handle date value change
	const handleDateChange = (date) => {
		setAllValues({ ...allValues, birthDate: date });
	};

	return (
		<div>
			<form>
				<Grid container alignItems="center" spacing={4}>
					<Grid item xs={12}>
						<Typography variant="h6" component="h6">
							Informations utilisateur
						</Typography>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormLabel component="legend">Genre</FormLabel>
						<RadioGroup
							row
							name="gender"
							value={allValues.gender}
							onChange={changeHandler}
						>
							<FormControlLabel
								value="male"
								control={<Radio color="primary" />}
								label="Homme"
							/>
							<FormControlLabel
								value="female"
								control={<Radio color="primary" />}
								label="Femme"
							/>
							<FormControlLabel
								value="other"
								control={<Radio color="primary" />}
								label="Non renseigné"
							/>
						</RadioGroup>
					</Grid>
					<Grid item xs={12} md={6}>
						<KeyboardDatePicker
							className={classes.textInput}
							inputVariant="outlined"
							label="Date de naissance"
							clearable
							value={allValues.birthDate}
							placeholder="JJ/MM/AAAA"
							onChange={(date) => handleDateChange(date)}
							format="dd/MM/yyyy"
							maxDate={maxBirthDate()}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							className={classes.textInput}
							label="Nom"
							name="lastname"
							variant="outlined"
							value={allValues.lastname}
							onChange={changeHandler}
							required
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							className={classes.textInput}
							label="Prénom"
							name="firstname"
							variant="outlined"
							value={allValues.firstname}
							onChange={changeHandler}
							required
						/>
					</Grid>

					<Grid item xs={12} align="right">
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								onSubmit(allValues);
							}}
						>
							Enregistrer
						</Button>
					</Grid>
				</Grid>
			</form>
		</div>
	);
}

export default ProfileInformations;
