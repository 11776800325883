import {
	Button,
	Chip,
	FormControl,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import React, { useState } from "react";

const useStyle = makeStyles((theme) => ({
	textInput: {
		width: "100%",
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	label: { background: "white" },
}));

const names = [
	"Berger d’Anatolie",
	"Dogue du Tibet",
	"Maremme Abruzzes",
	"Mastin espagnol",
	"Montagne des Pyrénées",
	"Patou",
];

/**
 * Component to display and modify professional informations of user
 * @param user current user
 * @param onSubmit callback to call when user modify his informations
 * @returns
 */
function ProfessionalProfileInformations({ user, onSubmit }) {
	const classes = useStyle();

	//current user values
	const [allValues, setAllValues] = useState({
		numberCPT: user.numberCPT,
		numberMale: user.numberMale,
		numberFemale: user.numberFemale,
		CPTtype: user.CPTtype || [],
	});

	//handle values change
	const changeHandler = (e) => {
		setAllValues({ ...allValues, [e.target.name]: e.target.value });
	};

	return (
		<div>
			<form>
				<Grid container alignItems="center" spacing={4}>
					<Grid item xs={12}>
						<Typography variant="h6" component="h6">
							Informations générales
						</Typography>
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField
							className={classes.textInput}
							type="number"
							label="Nombre de CPT"
							name="numberCPT"
							variant="outlined"
							value={allValues.numberCPT}
							onChange={changeHandler}
							required
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField
							className={classes.textInput}
							type="number"
							label="Nombre de CPT Mâle"
							name="numberMale"
							variant="outlined"
							value={allValues.numberMale}
							onChange={changeHandler}
							required
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField
							className={classes.textInput}
							type="number"
							label="Nombre de CPT Femelle"
							name="numberFemale"
							variant="outlined"
							value={allValues.numberFemale}
							onChange={changeHandler}
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel
								className={classes.label}
								id="demo-mutiple-chip-label"
							>
								Type de CPT
							</InputLabel>
							<Select
								labelId="demo-mutiple-chip-label"
								name="CPTtype"
								value={allValues.CPTtype}
								onChange={changeHandler}
								label="Type de CPT"
								multiple
								input={<OutlinedInput />}
								renderValue={(selected) => (
									<div className={classes.chips}>
										{selected.map((value) => (
											<Chip
												key={value}
												label={value}
												className={classes.chip}
											/>
										))}
									</div>
								)}
							>
								{names.map((name) => (
									<MenuItem key={name} value={name}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} align="right">
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								onSubmit(allValues);
							}}
						>
							Enregistrer
						</Button>
					</Grid>
				</Grid>
			</form>
		</div>
	);
}

export default ProfessionalProfileInformations;
