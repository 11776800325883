import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setSatelliteStyle,
	setThreeDMap,
	setUserInformation,
} from "../../../actions";
import { MapSelector, UserSelector } from "../../../selectors";
import getUserAPI from "../../../services/getUserAPI";
import isUserConnected from "../../../utils/isUserConnected";
import {
	geolocate,
	initialPointClicked,
	initialPosition,
	pointsId,
	zonesId,
} from "../constants";
import addSourceAndLayer from "../events/addSourceAndLayer";
import addSourceAndLayerDrawZone from "../events/addSourceAndLayerDrawZone";
import onClickCluster from "../events/onClickCluster";
import onEnterPoint from "../events/onEnterPoint";
import onEnterZone from "../events/onEnterZone";
import onLeaveElement from "../events/onLeaveElement";
import createNewPointAPI from "../services/createNewPointAPI";
import createNewZoneAPI from "../services/createNewZoneAPI";
import pointPopupHTML from "../utils/pointPopupHTML";
import zonePopupHTML from "../utils/zonePopupHTML";
import LikeInfo from "./LikeInfo";
import NewPointDialog from "./NewPointDialog";
import NewZoneDialog from "./NewZoneDialog";
import NewZoneInfo from "./NewZoneInfo";
import PointInfo from "./PointInfo";
import SideBarAction from "./SideBarAction";
import ZoneInfo from "./ZoneInfo";

//Access token for the mapbox map component
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const useStyle = makeStyles((theme) => ({
	map: {
		height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		width: "100vw",
		[theme.breakpoints.down("sm")]: {
			minHeight: `calc(100vh - 40px)`,
		},
	},
	sidebar: {
		zIndex: 1,
		position: "absolute",
		bottom: theme.spacing(3),
		left: 0,
	},
	popup: {
		color: "rgba(35, 55, 75, 0.9) ",
	},
}));

/**
 * Component for the map
 * @returns
 */
function HomepageMap() {
	const classes = useStyle();
	const dispatch = useDispatch();

	//current map container reference
	const mapContainer = useRef(null);

	//current map reference
	const map = useRef(null);

	//current position on the map
	const position = initialPosition;

	//current map from the redux store
	const currentMap = useSelector(MapSelector);

	//current user
	const connectedUser = useSelector(UserSelector);

	//current map style , default : outdoors
	const [satelliteMap, setSatelliteMap] = useState(currentMap.satelliteMap);

	//if map is 3d or not
	const [mapWithRelief, setMapWithRelief] = useState(currentMap.isThreeD);

	//current zone hovered
	const [zoneHovered, setZoneHovered] = useState();

	//current point hovered
	const [pointHovered, setPointHovered] = useState();

	//current point clicked
	const [pointClicked, setPointClicked] = useState(initialPointClicked);

	//state of user adding a new point
	const [addMarker, setAddMarker] = useState(false);

	//state of user adding a new polygone
	const [addZone, setAddZone] = useState(false);

	//state of the new point dialog
	const [openAddDialog, setOpenAddDialog] = useState(false);

	//state of the new zone dialog
	const [openAddZoneDialog, setOpenAddZoneDialog] = useState(false);

	//position of the marker for the new point
	const [marker, setMarker] = useState();

	//feature collection for the new zone
	const [zoneMarkers, setZoneMarkers] = useState({
		type: "FeatureCollection",
		features: [],
	});

	//feature collection for the new zone
	const [linestring, setLinestring] = useState({
		type: "Feature",
		geometry: {
			type: "LineString",
			coordinates: [],
		},
	});

	/************************************************************
	 * POINT CREATION
	 **********************************************************/
	//open dialog to create a new point
	const handleOpenAddDialog = () => {
		setOpenAddDialog(!openAddDialog);
	};
	//handle canceling point creation
	const handleCancelAddDialog = () => {
		setAddMarker(false);
		handleOpenAddDialog();
	};
	//handle submitting point creation
	const handleSubmitAddDialog = (values) => {
		createNewPointAPI(connectedUser.token, marker.getLngLat(), values).then(
			() => {
				handleOpenAddDialog();
				setAddMarker(false);
			}
		);
	};

	/************************************************************
	 * ZONE CREATION
	 **********************************************************/
	//open dialog to create a new zone
	const handleOpenAddZoneDialog = () => {
		setOpenAddZoneDialog(!openAddZoneDialog);
	};
	//handle zone creation when user submit
	const handleZoneSubmit = (name) => {
		createNewZoneAPI(connectedUser.token, zoneMarkers.features, name).then(
			({ user }) => {
				if (user) {
					dispatch(setUserInformation(user));
				}
				setAddZone(false);
				handleOpenAddZoneDialog();
				handleZoneReset();
			}
		);
	};
	//handle when user undo a new point of the zone creation
	const handleZoneUndo = () => {
		if (zoneMarkers.features.length > 0) {
			zoneMarkers.features.pop();
			zoneMarkers.features.pop();
			if (zoneMarkers.features.length > 1) {
				linestring.geometry.coordinates = zoneMarkers.features.map(
					(point) => point.geometry.coordinates
				);
				zoneMarkers.features.push(linestring);
			}
			map.current.getSource("zoneMarkers").setData(zoneMarkers);
		}
	};
	//handle cancelling zone creation
	const handleZoneCancel = () => {
		setAddZone(false);
		handleZoneReset();
	};
	//handle zone reset
	const handleZoneReset = () => {
		setZoneMarkers({
			type: "FeatureCollection",
			features: [],
		});
		setLinestring({
			type: "Feature",
			geometry: {
				type: "LineString",
				coordinates: [],
			},
		});
	};

	/************************************************************
	 * MAP STYLE CHANGE
	 **********************************************************/
	//handle map change to 3D or 2D
	const handleMapWithRelief = (withRelief) => {
		if (withRelief) {
			if (map.current && map.current.getSource("mapbox-dem")) {
				// add the DEM source as a terrain layer
				map.current.setTerrain({ source: "mapbox-dem" });
			}
		} else {
			map.current.setTerrain(null);
		}
		setMapWithRelief(withRelief);
		dispatch(setThreeDMap(withRelief));
	};
	//handle map change to satellite style or default style
	const handleSatelliteMap = (isSatellite) => {
		if (isSatellite) {
			if (map.current) {
				// add the DEM source as a terrain layer
				map.current.setStyle(
					process.env.REACT_APP_MAPBOX_STYLE_SATELLITE
				);
			}
		} else {
			map.current.setStyle(process.env.REACT_APP_MAPBOX_STYLE_OUTDOOR);
		}
		setSatelliteMap(isSatellite);
		dispatch(setSatelliteStyle(isSatellite));
		setMapWithRelief(false);
		dispatch(setThreeDMap(false));
	};

	/************************************************************
	 * MAP SETUP
	 * Setup the mapbox map with controls and events
	 **********************************************************/
	useEffect(() => {
		if (!addMarker && !addZone) {
			if (map.current) {
				map.current.remove();
			}
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: satelliteMap
					? process.env.REACT_APP_MAPBOX_STYLE_SATELLITE
					: process.env.REACT_APP_MAPBOX_STYLE_OUTDOOR,
				center: [position.lng, position.lat],
				zoom: position.zoom,
			});
			//Add fullscreenControl
			map.current.addControl(new mapboxgl.FullscreenControl());
			//Add NavigationControl(zoom , position)
			map.current.addControl(new mapboxgl.NavigationControl());
			//Add GeolocateControl
			map.current.addControl(geolocate);
		}

		//add sources and layer when map loaded
		map.current.on("style.load", () =>
			addSourceAndLayer(map, connectedUser, mapWithRelief)
		);

		// if map initialized
		if (map.current) {
			var currentElement = null;
			var popup = new mapboxgl.Popup();

			//if a user is connected
			if (isUserConnected(connectedUser)) {
				//reset state when map clicked
				map.current.on("click", () => {
					setPointClicked(initialPointClicked);
				});

				//Add a popup when clicked on a polygon
				map.current.on("click", zonesId, (e) => {
					popup.remove();
					setPointClicked(initialPointClicked);
					const zone = e.features[0];
					popup = new mapboxgl.Popup({
						closeButton: false,
					}).setLngLat(e.lngLat);
					getUserAPI(
						zone.properties.ownerId,
						connectedUser.token
					).then(({ user }) => {
						popup.setHTML(zonePopupHTML(zone, user, connectedUser));
					});
					popup.addTo(map.current);
				});

				//get data from current hovered zone
				map.current.on("mousemove", zonesId, (e) => {
					onEnterZone(currentElement, map, setZoneHovered)(e);
					if (e.features.length > 0) {
						currentElement = e.features[0].id;
					}
				});

				//update data when mouse leaving current hovered element
				map.current.on("mouseleave", zonesId, () =>
					onLeaveElement(currentElement, map, zonesId, setZoneHovered)
				);

				//Add a popup when clicked on a point
				map.current.on("click", pointsId, (e) => {
					popup.remove();
					const point = e.features[0];
					popup = new mapboxgl.Popup({
						offset: [0, -12],
						closeButton: false,
					}).setLngLat(point.geometry.coordinates);
					if (!point.properties.cluster) {
						getUserAPI(
							point.properties.createdBy,
							connectedUser.token
						).then(({ user }) => {
							popup.setHTML(pointPopupHTML(point, user));
						});

						popup.addTo(map.current);
						setPointClicked({
							clicked: true,
							id: point.id,
							properties: point.properties,
						});
					}
					map.current.flyTo({
						center: point.geometry.coordinates,
					});
				});

				//get data from current hovered point
				map.current.on("mousemove", pointsId, (e) => {
					setZoneHovered();
					onEnterPoint(currentElement, map, setPointHovered)(e);
					if (e.features.length > 0) {
						currentElement = e.features[0].id;
					}
				});

				//update data when mouse leaving current hovered element
				map.current.on("mouseleave", pointsId, () =>
					onLeaveElement(
						currentElement,
						map,
						pointsId,
						setPointHovered
					)
				);

				// inspect a cluster on click
				map.current.on("click", "clusters", (e) =>
					onClickCluster(map)(e)
				);

				//if the user want to add a point
				if (addMarker) {
					setPointClicked(initialPointClicked);
					map.current.once("click", (e) => {
						var marker = new mapboxgl.Marker({
							draggable: true,
							color: "#808080",
							scale: 0.8,
						});
						marker.setLngLat(e.lngLat).addTo(map.current);
						handleOpenAddDialog();
						setMarker(marker);
						marker
							.getElement()
							.addEventListener("click", handleOpenAddDialog);
						marker.on("dragend", handleOpenAddDialog);
					});
				}

				//if the user want to add a zone
				if (addZone) {
					addSourceAndLayerDrawZone(map, zoneMarkers);
					map.current.on("click", (e) => {
						const features = map.current.queryRenderedFeatures(
							e.point,
							{
								layers: ["measure-points"],
							}
						);
						// Remove the linestring from the group so we can redraw it based on the points collection.
						if (zoneMarkers.features.length > 1)
							zoneMarkers.features.pop();
						//If a feature was clicked, submit the polygone.
						if (features.length) {
							const id = features[0].properties.id;
							//if the point clicked is the first created
							if (id === zoneMarkers.features[0].properties.id) {
								handleOpenAddZoneDialog();
							}
						} else {
							const point = {
								type: "Feature",
								geometry: {
									type: "Point",
									coordinates: [e.lngLat.lng, e.lngLat.lat],
								},
								properties: {
									id: String(new Date().getTime()),
								},
							};
							zoneMarkers.features.push(point);
						}
						//create the linestring between point
						if (zoneMarkers.features.length > 1) {
							linestring.geometry.coordinates =
								zoneMarkers.features.map(
									(point) => point.geometry.coordinates
								);
							zoneMarkers.features.push(linestring);
						}
						map.current
							.getSource("zoneMarkers")
							.setData(zoneMarkers);
					});
					map.current.on("mousemove", (e) => {
						const features = map.current.queryRenderedFeatures(
							e.point,
							{
								layers: ["measure-points"],
							}
						);
						// Change the cursor to a pointer when hovering over a point on the map. Otherwise cursor is a crosshair.
						map.current.getCanvas().style.cursor = features.length
							? "pointer"
							: "crosshair";
					});
				}
			} else {
				setAddZone(false);
				setAddMarker(false);
				handleZoneReset();
			}
		}
		// eslint-disable-next-line
	}, [connectedUser.token, addMarker, addZone]);

	return (
		<div>
			{isUserConnected(connectedUser) && (
				<>
					<div className={classes.sidebar}>
						<ZoneInfo zone={zoneHovered} />
						<PointInfo point={pointHovered} />
					</div>
					<SideBarAction
						user={connectedUser}
						addMarker={addMarker}
						setAddMarker={setAddMarker}
						addZone={addZone}
						setAddZone={setAddZone}
						mapWithRelief={mapWithRelief}
						handleMapWithRelief={handleMapWithRelief}
						satelliteMap={satelliteMap}
						handleSatelliteMap={handleSatelliteMap}
						map={map}
					/>
					<LikeInfo
						user={connectedUser}
						point={pointClicked}
						onClose={setPointClicked}
					/>
					<NewZoneInfo
						addZone={addZone}
						handleZoneUndo={handleZoneUndo}
						handleZoneCancel={handleZoneCancel}
						handleZoneSubmit={handleOpenAddZoneDialog}
					/>
					<NewPointDialog
						open={openAddDialog}
						onClose={handleOpenAddDialog}
						onCancel={handleCancelAddDialog}
						onSubmit={handleSubmitAddDialog}
					/>
					<NewZoneDialog
						open={openAddZoneDialog}
						onClose={handleOpenAddZoneDialog}
						onSubmit={handleZoneSubmit}
					/>
				</>
			)}
			<div ref={mapContainer} className={classes.map} />
		</div>
	);
}

export default HomepageMap;
