import {
	AppBar,
	Grid,
	IconButton,
	makeStyles,
	Toolbar,
} from "@material-ui/core";
import {
	AccountCircleRounded,
	HomeRounded,
	InfoRounded,
} from "@material-ui/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UserSelector } from "../../selectors";
import isUserConnected from "../../utils/isUserConnected";
import SignInDrawer from "../SignIn/SignInDrawer";
import UserMenuDrawer from "../UserMenu/UserMenuDrawer";

const useStyles = makeStyles((theme) => ({
	appBar: {
		top: "auto",
		bottom: 0,
		height: "40px",
		minHeight: 0,
		zIndex: theme.zIndex.drawer + 1000,
		background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
	},

	textColor: {
		color: theme.palette.secondary.light,
	},
}));

/**
 * Component for mobile view appbar
 * @returns
 */
function BottomTopBar() {
	const classes = useStyles();

	//current user connected
	const user = useSelector(UserSelector);

	//state of connection drawer
	const [openConnect, setOpenConnect] = useState(false);

	//state of menu drawer
	const [openMenu, setOpenMenu] = useState(false);

	//toggle open menu drawer
	const toggleDrawer = () => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		if (!isUserConnected(user)) {
			setOpenConnect(!openConnect);
		} else {
			setOpenMenu(!openMenu);
		}
	};

	//toggle close menu drawer
	const closeDrawer = () => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		if (openConnect || openMenu) {
			if (!isUserConnected(user)) {
				setOpenConnect(!openConnect);
			} else {
				setOpenMenu(!openMenu);
			}
		}
	};

	return (
		<AppBar position="fixed" color="primary" className={classes.appBar}>
			<Toolbar className={classes.appBar}>
				<Grid container direction="row">
					<Grid item xs align="center">
						<IconButton
							component={Link}
							to="/"
							onClick={closeDrawer()}
							className={classes.textColor}
						>
							<HomeRounded />
						</IconButton>
					</Grid>
					<Grid item xs align="center">
						<IconButton
							component={Link}
							to="/informations"
							onClick={closeDrawer()}
							className={classes.textColor}
						>
							<InfoRounded />
						</IconButton>
					</Grid>

					<Grid item xs align="center">
						<IconButton
							className={classes.textColor}
							onClick={toggleDrawer()}
						>
							<AccountCircleRounded />
						</IconButton>
					</Grid>
				</Grid>
			</Toolbar>
			<SignInDrawer open={openConnect} onClose={toggleDrawer()} />
			<UserMenuDrawer open={openMenu} onClose={toggleDrawer()} />
		</AppBar>
	);
}

export default BottomTopBar;
