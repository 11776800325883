import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { UserSelector } from "../../../selectors";
import isUserProfessionnal from "../../../utils/isUserProfessionnal";
import ProfessionalProfileInformationsContainer from "./containers/ProfessionalProfileInformationsContainer";
import ZoneContainer from "./containers/ZoneContainer";

const useStyle = makeStyles((theme) => ({
	divider: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
}));

/**
 * Component for the User activty parameter
 * @returns
 */
function Activity() {
	const classes = useStyle();

	// get user from the store
	const user = useSelector(UserSelector);

	return (
		<>
			{isUserProfessionnal(user.roles) ? (
				<>
					<Grid item xs>
						<ProfessionalProfileInformationsContainer />
					</Grid>
					<Divider className={classes.divider} />
					<Grid item xs>
						<ZoneContainer user={user} />
					</Grid>
				</>
			) : (
				<Grid item xs>
					<Typography>
						Cette page est réservée aux éleveurs et aux bergers.
					</Typography>
				</Grid>
			)}
		</>
	);
}

export default Activity;
