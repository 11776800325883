import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import React from "react";

function ConfirmDeleteDialog({ open, onClose, onDelete }) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">
				Etes-vous sur de vouloir supprimer l'élément ?
			</DialogTitle>

			<DialogActions>
				<Button onClick={onClose} color="primary">
					Annuler
				</Button>
				<Button
					onClick={() => {
						onDelete();
					}}
					color="primary"
					variant="contained"
				>
					Confirmer
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmDeleteDialog;
