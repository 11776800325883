/**
 * Change zone values
 * @param {*} user current user
 * @param {*} zoneId selected zone id
 * @param {*} allValues zoen values to change
 * @returns
 */
const modifyZoneInformationsAPI = (user, zoneId, allValues) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/zone/modify/informations`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-access-token": user.token,
				},
				body: JSON.stringify({
					id: zoneId,
					properties: { ...allValues },
				}),
			}
		);
		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({
							message: json.message,
							zones: json.zones,
						});
					} else {
						reject(json.message);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export default modifyZoneInformationsAPI;
