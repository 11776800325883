import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router-dom";
import passwordIsValid from "../../../utils/passwordIsValid";
import ForgottenPasswordForm from "../components/ForgottenPasswordForm";
import changeForgottenPasswordAPI from "../services/changeForgottenPasswordAPI";

/**
 * Container for the Forgotten password component
 * @param secret secret code
 * @returns
 */
function ForgottenPasswordFormContainer({ secret }) {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	//send new password to the server
	const onSubmit = (newPassword, confirmNewPassword) => {
		if (passwordIsValid(newPassword)) {
			if (newPassword === confirmNewPassword) {
				changeForgottenPasswordAPI(newPassword, secret)
					.then((message) => {
						enqueueSnackbar(message, {
							variant: "success",
						});
						history.replace("/");
					})
					.catch((err) => {
						enqueueSnackbar(err, {
							variant: "error",
						});
					});
			}
		}
	};

	return <ForgottenPasswordForm onSubmit={onSubmit} />;
}

export default ForgottenPasswordFormContainer;
