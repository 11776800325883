import {
	FormControlLabel,
	Grid,
	makeStyles,
	Paper,
	Radio,
	RadioGroup,
	Typography,
} from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
	paper: {
		[theme.breakpoints.up("md")]: {
			width: "50vw",
		},
		padding: theme.spacing(2),
	},
	subTitle: { marginBottom: theme.spacing(2) },
	textInput: {
		width: "100%",
	},
}));

/**
 * Component for user role signUp step
 * @returns
 */
function SignUpRole({ values, setValues }) {
	const classes = useStyle();

	return (
		<div>
			<Paper className={classes.paper} elevation={3}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography className={classes.subTitle} variant="h6">
							Choisissez votre type de profil selon votre activité
						</Typography>
						<RadioGroup
							name="role"
							value={values.role}
							onChange={setValues}
						>
							<FormControlLabel
								value="breeder"
								control={<Radio color="primary" />}
								label="Eleveur"
							/>
							<FormControlLabel
								value="sheperd"
								control={<Radio color="primary" />}
								label="Berger"
							/>
							<FormControlLabel
								value="user"
								control={<Radio color="primary" />}
								label="Particulier"
							/>
						</RadioGroup>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
}

export default SignUpRole;
