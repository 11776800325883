/**
 * Send data to modify user
 * @param {*} user current user
 * @param {*} formData data to modify
 * @returns
 */
const modifyProfileImageAPI = (user, formData) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/modify/profileImage`,
			{
				method: "POST",
				headers: {
					"x-access-token": user.token,
				},
				body: formData,
			}
		);
		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({ user: json.user, message: json.message });
					} else {
						reject(json.err);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export default modifyProfileImageAPI;
