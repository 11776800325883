import {
	Button,
	Container,
	Grid,
	makeStyles,
	Step,
	StepLabel,
	Stepper,
} from "@material-ui/core";
import { validate } from "email-validator";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserInformation, setUserRoles, setUserToken } from "../../actions";
import sendConfirmMailAPI from "../../services/sendConfirmMailAPI";
import signInAPI from "../../services/signInAPI";
import maxBirthDate from "../../utils/maxBirthDate";
import passwordIsValid from "../../utils/passwordIsValid";
import signUpAPI from "./services/SignUpAPI";
import { getStepContent, getSteps } from "./utils/GetSteps";

const useStyle = makeStyles((theme) => ({
	root: { minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)` },
	content: {
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
	},
	buttons: { marginBottom: theme.spacing(3), marginTop: theme.spacing(3) },
	backButton: {
		marginRight: theme.spacing(1),
	},
}));

/**
 * Main component for the signUp page
 * @returns
 */
function SignUpPage() {
	const classes = useStyle();

	//All the steps components for the sign Up page
	const steps = getSteps();
	const history = useHistory();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	//Current step of the sign Up
	const [activeStep, setActiveStep] = useState(0);

	//Values from the  sign up form to send to the server
	const [allValues, setAllValues] = useState({
		role: "user",
		gender: "other",
		birthDate: maxBirthDate(),
		lastname: "",
		firstname: "",
		email: "",
		password: "",
		confirmPassword: "",
		accept: false,
	});

	//handle the new value to change in the state
	const changeHandler = (e) => {
		setAllValues({ ...allValues, [e.target.name]: e.target.value });
	};

	//handle the new value of the date to change in the state
	const handleDateChange = (date) => {
		setAllValues({ ...allValues, birthDate: date });
	};

	//handle the new value of the accept conditions checkbox to change in the state
	const handleAcceptChange = (accept) => {
		setAllValues({ ...allValues, accept: accept });
	};

	//handle move between sign up step
	const handleStep = (step) => {
		setActiveStep((prevActiveStep) => prevActiveStep + step);
	};

	//submit values to the server if everything correct then sign in the user and send mail confirmation
	const onSubmit = () => {
		if (
			allValues.lastname !== "" &&
			allValues.firstname !== "" &&
			allValues.birthDate !== null &&
			allValues.email !== "" &&
			allValues.password !== "" &&
			allValues.confirmPassword !== ""
		) {
			if (validate(allValues.email)) {
				if (passwordIsValid(allValues.password)) {
					if (allValues.password === allValues.confirmPassword) {
						if (allValues.accept) {
							//send request to the server with all values
							signUpAPI(allValues)
								.then(({ message }) => {
									enqueueSnackbar(message, {
										variant: "success",
									});
									//sign in the current user
									signInAPI(
										allValues.email,
										allValues.password
									)
										.then(
											({
												message,
												user,
												roles,
												token,
											}) => {
												//store data in the reducer store
												dispatch(
													setUserInformation(user)
												);
												dispatch(setUserRoles(roles));
												dispatch(setUserToken(token));
												enqueueSnackbar(message, {
													variant: "info",
												});
												//send a confirmation mail to the current user
												sendConfirmMailAPI(token)
													.then(({ message }) => {
														enqueueSnackbar(
															message,
															{
																variant:
																	"success",
															}
														);
													})
													.catch((err) => {
														enqueueSnackbar(err, {
															variant: "error",
														});
													});
												history.push("/");
											}
										)
										.catch((err) => {
											enqueueSnackbar(err, {
												variant: "error",
											});
										});
								})
								.catch((err) => {
									enqueueSnackbar(err, {
										variant: "error",
									});
								});
						} else {
							enqueueSnackbar(
								"Vous devez accepter les conditions d'utilisations",
								{
									variant: "error",
								}
							);
						}
					} else {
						enqueueSnackbar(
							"Les mots de passe ne sont pas identiques",
							{
								variant: "error",
							}
						);
					}
				} else {
					enqueueSnackbar(
						"Le mot de passe ne respecte pas les règles de sécurité",
						{
							variant: "error",
						}
					);
				}
			} else {
				enqueueSnackbar("Merci d'indiquer une adresse mail valide", {
					variant: "error",
				});
			}
		} else {
			enqueueSnackbar(
				"Merci de remplir tous les champs de l'inscription",
				{
					variant: "error",
				}
			);
		}
	};

	return (
		<Container className={classes.root}>
			<Grid
				className={classes.content}
				container
				direction="column"
				alignItems="center"
			>
				<Grid item xs>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</Grid>
				<Grid item xs>
					{getStepContent(
						activeStep,
						allValues,
						changeHandler,
						handleDateChange,
						handleAcceptChange
					)}
				</Grid>
				<Grid
					className={classes.buttons}
					item
					container
					xs
					alignContent="flex-end"
					justifyContent="center"
				>
					<Button
						disabled={activeStep === 0}
						onClick={() => handleStep(-1)}
						className={classes.backButton}
					>
						Retour
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							if (activeStep === steps.length - 1) {
								onSubmit();
							} else {
								handleStep(1);
							}
						}}
					>
						{activeStep === steps.length - 1
							? "S'inscrire"
							: "Suivant"}
					</Button>
				</Grid>
			</Grid>
		</Container>
	);
}

export default SignUpPage;
