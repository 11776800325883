import { Container, makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TabPanel from "../../components/TabPanel";
import AccidentInfo from "./components/AccidentInfo";
import DogBehaviourInfo from "./components/DogBehaviourInfo";
import MoreInfo from "./components/MoreInfo";
import PastoralBehaviourInfo from "./components/PastoralBehaviourInfo";
import PrepareHisTrail from "./components/PrepareHisTrailInfo";
import WelcomeInfo from "./components/WelcomeInfo";

const useStyle = makeStyles((theme) => ({
	root: { minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)` },
	tabs: { color: theme.palette.primary },
}));

function InformationPage() {
	const classes = useStyle();
	const { id } = useParams();

	const [value, setValue] = useState(parseInt(id) || 0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Container className={classes.root}>
			<Paper>
				<Tabs
					className={classes.tabs}
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					variant="scrollable"
					scrollButtons="auto"
				>
					<Tab label="Bienvenue sur Patoutéou" />
					<Tab label="Comment préparer sa course" />
					<Tab label="Comment se comporter face à un troupeau et ses CPT" />
					<Tab label="Comment se comporter en milieu pastoral" />
					<Tab label="Que faire en cas d’accident par morsure ?" />
					<Tab label="Plus d'informations" />
				</Tabs>
			</Paper>
			<TabPanel value={value} index={0}>
				<WelcomeInfo />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<PrepareHisTrail />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<DogBehaviourInfo />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<PastoralBehaviourInfo />
			</TabPanel>
			<TabPanel value={value} index={4}>
				<AccidentInfo />
			</TabPanel>
			<TabPanel value={value} index={5}>
				<MoreInfo />
			</TabPanel>
		</Container>
	);
}

export default InformationPage;
