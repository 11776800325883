//List of UserReducer Actions
export const SET_USER_INFORMATIONS = "SET_USER_INFORMATIONS";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_USER_LIKES = "SET_USER_LIKES";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_USER_DISCONNECTED = "SET_USER_DISCONNECTED";

//List of WelcomeReducer Actions
export const SET_WELCOME_STATUS = "SET_WELCOME_STATUS";

//List of StyleReducer Actions
export const SET_DARK_MODE = "SET_DARK_MODE";

//List of MapReducer Actions
export const SET_SATELLITE_MAP = "SET_SATELLITE_MAP";
export const SET_THREE_D_MAP = "SET_THREE_D_MAP";
