import {
	FormControlLabel,
	FormLabel,
	Grid,
	makeStyles,
	Paper,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import maxBirthDate from "../../../utils/maxBirthDate";

const useStyle = makeStyles((theme) => ({
	paper: {
		[theme.breakpoints.up("md")]: {
			width: "50vw",
		},
		padding: theme.spacing(2),
	},
	subTitle: { marginBottom: theme.spacing(2) },
	textInput: {
		width: "100%",
	},
}));

/**
 * Component for user informations signUp step
 * @returns
 */
function SignUpInformations({ values, setValues, handleDate }) {
	const classes = useStyle();

	return (
		<Paper className={classes.paper} elevation={3}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography className={classes.subTitle} variant="h6">
						Vos informations
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<FormLabel component="legend">Genre</FormLabel>
					<RadioGroup
						row
						name="gender"
						value={values.gender}
						onChange={setValues}
					>
						<FormControlLabel
							value="male"
							control={<Radio color="primary" />}
							label="Homme"
						/>
						<FormControlLabel
							value="female"
							control={<Radio color="primary" />}
							label="Femme"
						/>
						<FormControlLabel
							value="other"
							control={<Radio color="primary" />}
							label="Non renseigné"
						/>
					</RadioGroup>
				</Grid>
				<Grid item xs={12}>
					<KeyboardDatePicker
						className={classes.textInput}
						inputVariant="outlined"
						label="Date de naissance"
						clearable
						value={values.birthDate}
						placeholder="JJ/MM/AAAA"
						onChange={(date) => handleDate(date)}
						format="dd/MM/yyyy"
						maxDate={maxBirthDate()}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Nom"
						name="lastname"
						variant="outlined"
						value={values.lastname}
						onChange={setValues}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Prénom"
						name="firstname"
						variant="outlined"
						value={values.firstname}
						onChange={setValues}
						required
					/>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default SignUpInformations;
