/**
 * Change score of the point depending on user like or not
 * @param {*} token current user token
 * @param {*} id point id
 * @param {*} count +1 or -1
 * @returns
 */
const changePointUserCount = (token, id, count) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/point/count`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-access-token": token,
				},
				body: JSON.stringify({ id, count }),
			}
		);
		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({ likes: json.likes });
					} else {
						reject(json.message);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export default changePointUserCount;
