/**
 * Request to send a verification mail to a user
 * @param {*} token user's token
 * @returns
 */
const sendConfirmMailAPI = (token) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/verify/email`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"x-access-token": token,
				},
			}
		);
		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({
							message: json.message,
						});
					} else {
						reject(json.message);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export default sendConfirmMailAPI;
