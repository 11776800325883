import {
	Grid,
	Hidden,
	IconButton,
	makeStyles,
	Paper,
	Typography,
} from "@material-ui/core";
import { MenuRounded } from "@material-ui/icons";
import React, { useState } from "react";
import UserMenuDrawer from "../../../components/UserMenu/UserMenuDrawer";
import Account from "../Account";
import Activity from "../Activity";
import Administration from "../Administration";
import Profile from "../Profile";
import Settings from "../Settings";

const useStyle = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	title: {
		fontWeight: "normal",
	},
	menuButton: { fontSize: theme.spacing(4) },
}));

//get page title depending on id
const title = (id) => {
	switch (id) {
		case "admin":
			return "Administration";
		case "activity":
			return "Mon Activité";
		case "profile":
			return "Mon Profil";
		case "account":
			return "Mon Compte";
		case "settings":
			return "Paramètres";

		default:
			break;
	}
};

//get page content depending on id
const content = (id) => {
	switch (id) {
		case "admin":
			return <Administration />;
		case "activity":
			return <Activity />;
		case "profile":
			return <Profile />;
		case "account":
			return <Account />;
		case "settings":
			return <Settings />;

		default:
			break;
	}
};

/**
 * Component for the User paramater page content
 * @returns
 */
function MySpaceContent({ id }) {
	const classes = useStyle();

	//state of the menu drawer
	const [openMenu, setOpenMenu] = useState(false);

	//handle menu drawer opening
	const toggleDrawer = () => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setOpenMenu(!openMenu);
	};

	return (
		<Paper className={classes.root} elevation={3}>
			<Grid container direction="column" alignItems="stretch" spacing={3}>
				<Grid item container xs direction="row" alignItems="center">
					<Hidden mdUp>
						<Grid item>
							<IconButton
								color="primary"
								onClick={toggleDrawer()}
							>
								<MenuRounded className={classes.menuButton} />
							</IconButton>
						</Grid>
					</Hidden>
					<Grid item>
						<Typography
							variant="h4"
							component="h4"
							className={classes.title}
						>
							{title(id)}
						</Typography>
					</Grid>
				</Grid>
				{content(id)}
			</Grid>
			<UserMenuDrawer open={openMenu} onClose={toggleDrawer()} />
		</Paper>
	);
}

export default MySpaceContent;
