/**
 * Transform a date object to formatted string
 * @param {*} date date to transform
 * @returns
 */
function createdAtToText(date) {
	const month_name = [
		"Janvier",
		"Février",
		"Mars",
		"Avril",
		"Mai",
		"Juin",
		"Juillet",
		"Août",
		"Septembre",
		"Octobre",
		"Novembre",
		"Décembre",
	];
	var d = new Date(date);
	var date_month = month_name[d.getMonth()];
	return date_month + " " + d.getFullYear();
}
export default createdAtToText;
