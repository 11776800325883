import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	makeStyles,
	TextField,
} from "@material-ui/core";
import { validate } from "email-validator";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import createForgottenPasswordRequestAPI from "../../services/createForgottenPasswordRequestAPI";

const useStyle = makeStyles((theme) => ({
	textInput: {
		width: "100%",
	},
}));

/**
 * Component to display forgotten password popup form
 * @param open if true, show the popup
 * @param setOpen change the visibility state
 */
function ForgottenPasswordDialog({ open, setOpen }) {
	const classes = useStyle();
	const { enqueueSnackbar } = useSnackbar();

	//email adress to send mail
	const [email, setEmail] = useState("");

	//submit password reset request
	const onSubmit = (email) => {
		createForgottenPasswordRequestAPI(email)
			.then(({ message }) => {
				enqueueSnackbar(message, {
					variant: "info",
				});
				setOpen(false);
			})
			.catch((err) => {
				enqueueSnackbar(err, {
					variant: "error",
				});
			});
	};

	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">
				Mot de passe oublié
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Saisissez votre adresse mail. Un mail vous sera envoyé afin
					de réinitialiser votre mot de passe.
				</DialogContentText>
				<TextField
					className={classes.textInput}
					label="Email"
					variant="outlined"
					value={email}
					onChange={(event) => setEmail(event.target.value)}
					error={email !== "" && !validate(email)}
					helperText={
						email !== "" &&
						!validate(email) &&
						"L'adresse mail doit être valide"
					}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setOpen(false);
					}}
					color="primary"
				>
					Annuler
				</Button>
				<Button
					onClick={() => {
						onSubmit(email);
					}}
					color="primary"
					variant="contained"
				>
					Envoyer
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ForgottenPasswordDialog;
