/**
 * Transform user roles to corresponding word
 * @param {*} roles list of user roles
 * @returns
 */
const roleToText = (roles) => {
	var res = "";
	roles.forEach((role) => {
		switch (role) {
			case "ROLE_BREEDER":
				res = "Eleveur";
				break;
			case "ROLE_SHEPERD":
				res = "Berger";
				break;
			case "ROLE_USER":
				res = "Particulier";
				break;

			default:
		}
	});
	return res;
};

export default roleToText;
