/**
 * request to own a zone
 * @param {*} token current user token
 * @param {*} zoneName name of the zone asked
 * @param {*} comment user comment
 * @returns
 */
const requestOwnershipAPI = (token, zoneName, comment) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/zone/requestOwnership`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-access-token": token,
				},
				body: JSON.stringify({ zoneName: zoneName, comment: comment }),
			}
		);
		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({ message: json.message });
					} else {
						reject(json.message);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export default requestOwnershipAPI;
