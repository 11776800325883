import DateFnsUtils from "@date-io/date-fns";
import { CssBaseline, withWidth } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { useSelector } from "react-redux";
import Routes from "./routes";
import { StyleSelector } from "./selectors";
import darkTheme from "./style/darkTheme";
import theme from "./style/theme";

/**
 * Main component of the React App (contain all providers)
 * @param {*} props app props
 * @returns
 */
function App(props) {
	const style = useSelector(StyleSelector);

	return (
		<ThemeProvider theme={style.dark ? darkTheme : theme}>
			<SnackbarProvider
				autoHideDuration={5000}
				maxSnack={3}
				anchorOrigin={
					props.width === "sm" || props.width === "xs"
						? { vertical: "top", horizontal: "center" }
						: { vertical: "bottom", horizontal: "center" }
				}
			>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<CssBaseline />
					<Routes />
					<CookieConsent
						location={
							props.width === "sm" || props.width === "xs"
								? "top"
								: "bottom"
						}
						buttonText="Accepter"
						cookieName="cookiesConsent"
						style={{
							background: "#ffffff",
							color: "#2E4159",
							fontSize: "16px",
						}}
						buttonStyle={{
							borderRadius: "10%",
							background: "#2E4159",
							color: "#ffffff",
							fontSize: "12px",
						}}
						expires={365}
					>
						En poursuivant votre navigation, vous acceptez
						l'utilisation de cookies.{" "}
						<span style={{ fontSize: "12px" }}>
							Leur utilisation est strictement réservée au bon
							fonctionnement du site pour vous garantir la
							meilleure expérience possible.{" "}
						</span>
						<a
							href="/informations"
							style={{
								fontSize: "12px",
								textDecoration: "none",
								color: "#2E4159",
							}}
						>
							En savoir plus
						</a>
					</CookieConsent>
				</MuiPickersUtilsProvider>
			</SnackbarProvider>
		</ThemeProvider>
	);
}
export default withWidth()(App);
