/**
 * check if a date equals to today's date
 * @param {*} someDate date to test
 * @returns
 */
const isToday = (someDate) => {
	const today = new Date();
	return (
		someDate.getDate() === today.getDate() &&
		someDate.getMonth() === today.getMonth() &&
		someDate.getFullYear() === today.getFullYear()
	);
};
export default isToday;
