import { Container, makeStyles } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import ForgottenPasswordFormContainer from "./containers/ForgottenPasswordFormContainer";

const useStyle = makeStyles((theme) => ({
	root: { paddingTop: theme.spacing(2) },
}));

/**
 * Main component of the ForgottenPassword page
 * @returns
 */
function ForgottenPasswordPage() {
	const classes = useStyle();
	//get the secret code from the URL parameter
	const { secret } = useParams();

	return (
		<Container className={classes.root}>
			<ForgottenPasswordFormContainer secret={secret} />
		</Container>
	);
}

export default ForgottenPasswordPage;
