import { FormControlLabel, Switch } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDarkMode } from "../../../../actions";
import { StyleSelector } from "../../../../selectors";

/**
 * Component for the darkmode setting
 * @returns
 */
function DarkMode() {
	const dispatch = useDispatch();

	//current app style
	const style = useSelector(StyleSelector);

	//state of the dark mode
	const [dark, setDark] = useState(style.dark);

	//handle darkmode value
	const handleChange = (event) => {
		setDark(event.target.checked);
		dispatch(setDarkMode());
	};

	return (
		<FormControlLabel
			control={
				<Switch
					checked={dark}
					onChange={handleChange}
					color="primary"
				/>
			}
			label="Mode sombre"
		/>
	);
}

export default DarkMode;
