/**
 * create a forgotten password request and send a email
 * @param {*} email email to send request
 * @returns
 */
const createForgottenPasswordRequestAPI = (email) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/user/forgottenPassword/request`,
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email }),
			}
		);

		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({
							message: json.message,
						});
					} else {
						reject(json.message);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export default createForgottenPasswordRequestAPI;
