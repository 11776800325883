import { validate } from "email-validator";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInformation } from "../../../../actions";
import { UserSelector } from "../../../../selectors";
import sendConfirmMailAPI from "../../../../services/sendConfirmMailAPI";
import AccountMail from "../components/AccountMail";
import modifyMailAPI from "../services/modifyMailAPI";

/**
 * Container for user mail modification
 * @returns
 */
function AccountMailContainer() {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	//current user
	const connectedUser = useSelector(UserSelector);

	//request to send mail confirmation to current user
	const onConfirm = () => {
		sendConfirmMailAPI(connectedUser.token)
			.then(({ message }) => {
				enqueueSnackbar(message, {
					variant: "success",
				});
			})
			.catch((err) => {
				enqueueSnackbar(err, {
					variant: "error",
				});
			});
	};

	//send new user mail then request to send mail confirmation to current user
	const onSubmit = (email) => {
		if (validate(email)) {
			modifyMailAPI(connectedUser, email)
				.then(({ user, message }) => {
					dispatch(setUserInformation(user));
					enqueueSnackbar(message, {
						variant: "success",
					});
					sendConfirmMailAPI(connectedUser.token)
						.then(({ message }) => {
							enqueueSnackbar(message, {
								variant: "success",
							});
						})
						.catch((err) => {
							enqueueSnackbar(err, {
								variant: "error",
							});
						});
				})
				.catch((err) => {
					enqueueSnackbar(err, {
						variant: "error",
					});
				});
		} else {
			enqueueSnackbar("Cette adresse mail n'est pas valide", {
				variant: "error",
			});
		}
	};

	return (
		<AccountMail
			user={connectedUser.data}
			onSubmit={onSubmit}
			onConfirm={onConfirm}
		/>
	);
}

export default AccountMailContainer;
