import { pointsId } from "../constants";

/**
 * handle click on point cluster
 * @param {*} map current map
 * @returns
 */
const onClickCluster = (map) => (e) => {
	var features = map.current.queryRenderedFeatures(e.point, {
		layers: ["clusters"],
	});
	var clusterId = features[0].properties.cluster_id;
	map.current
		.getSource(pointsId)
		.getClusterExpansionZoom(clusterId, function (err, zoom) {
			if (err) return;

			map.current.easeTo({
				center: features[0].geometry.coordinates,
				zoom: zoom,
			});
		});
};

export default onClickCluster;
