import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import LightTooltip from "../../../../components/LightToolTips";
import getZonesAPI from "../../../../services/getZonesAPI";
import ConfirmDeleteDialog from "../components/ConfirmDeleteDialog";
import deleteZoneAPI from "../services/deleteZoneAPI";
import getAllUserWithRoleAPI from "../services/getAllUserWithRoleAPI";
import ZoneAdminDialogContainer from "./ZoneAdminDialogContainer";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
}));

/**
 * Component for  the list of zones in admin page
 * @returns
 */
function ZoneListContainer({ user, open }) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	//array of zones
	const [zones, setZones] = useState([]);

	//array of users (only users with breeder or sheperd roles)
	const [users, setUsers] = useState([]);

	//current zone selected
	const [currentZone, setCurrentZone] = useState();

	//state of the zone dialog
	const [openDialog, setOpenDialog] = useState(false);

	//state of delete dialog
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	//handle opening of the zone dialog
	const handleDialog = (currentZone) => {
		setCurrentZone(currentZone);
		setOpenDialog(!openDialog);
	};

	//handle opening of delete dialog
	const handleDeleteDialog = () => {
		setOpenDeleteDialog(!openDeleteDialog);
	};

	//create request to delete current point selected
	const handleDelete = () => {
		deleteZoneAPI(user.token, currentZone.id).then(({ message }) => {
			enqueueSnackbar(message, {
				variant: "success",
			});
			handleDeleteDialog();
		});
	};

	//fetch all zones and professional users from the database
	useEffect(() => {
		if (open && !openDialog && !openDeleteDialog) {
			setUsers([]);
			getZonesAPI().then(({ zones }) => {
				setZones([...zones]);
				getAllUserWithRoleAPI(user.token, "breeder").then(
					({ users }) => {
						setUsers((user) => [...user, ...users.users]);
						getAllUserWithRoleAPI(user.token, "sheperd").then(
							({ users }) => {
								setUsers((user) => [...user, ...users.users]);
							}
						);
					}
				);
			});
		}
		// eslint-disable-next-line
	}, [open, openDialog, openDeleteDialog]);

	return (
		<div className={classes.root}>
			<List>
				{zones && zones.length > 0 ? (
					zones.map((zone, index) => {
						return (
							<ListItem
								key={index}
								button
								onClick={() => {
									handleDialog(zone);
								}}
							>
								{zone.properties.name}
								<ListItemSecondaryAction>
									<LightTooltip title="Supprimer">
										<IconButton
											edge="end"
											onClick={() => {
												setOpenDeleteDialog(true);
												setCurrentZone(zone);
											}}
										>
											<DeleteRounded />
										</IconButton>
									</LightTooltip>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})
				) : (
					<Typography> Aucun quartier trouvé</Typography>
				)}
			</List>
			<ZoneAdminDialogContainer
				user={user}
				currentZone={currentZone}
				users={users}
				open={openDialog}
				handleDialog={handleDialog}
			/>
			<ConfirmDeleteDialog
				open={openDeleteDialog}
				onClose={handleDeleteDialog}
				onDelete={handleDelete}
			/>
		</div>
	);
}

export default ZoneListContainer;
