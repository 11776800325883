import {
	Button,
	Grid,
	IconButton,
	InputAdornment,
	makeStyles,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";
import passwordIsValid from "../../../utils/passwordIsValid";

const useStyle = makeStyles((theme) => ({
	paper: { padding: theme.spacing(2) },
	textInput: {
		width: "100%",
	},
}));

/**
 * Component for the forgotten password form
 * @param onSubmit callback to send new password
 * @returns
 */
function ForgottenPasswordForm({ onSubmit }) {
	const classes = useStyle();

	//State of the new password
	const [newPassword, setNewPassword] = useState("");

	//State of the confirmation of the new password
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	//State of the visibility of the new password
	const [newPasswordIsVisible, setNewPasswordVisibility] = useState(false);

	//State of the visibility of the confiramtion of the new password
	const [confirmPasswordIsVisible, setConfirmPasswordVisibility] =
		useState(false);

	//handle when mouse down on password input
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<Paper className={classes.paper} elevation={3}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Réinitialisation de mot de passe
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Nouveau mot de passe"
						variant="outlined"
						value={newPassword}
						onChange={(event) => setNewPassword(event.target.value)}
						placeholder="*******"
						type={newPasswordIsVisible ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="Change la visibilité du mot de passe"
										onClick={() =>
											setNewPasswordVisibility(
												!newPasswordIsVisible
											)
										}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{newPasswordIsVisible ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={
							newPassword !== "" && !passwordIsValid(newPassword)
						}
						helperText={
							newPassword !== "" &&
							!passwordIsValid(newPassword) &&
							"Le mot de passe doit contenir au moins 8 caractères alphanumériques"
						}
						required
					/>
				</Grid>
				<Grid variant="outlined" item xs={12}>
					<TextField
						className={classes.textInput}
						label="Confirmation du nouveau mot de passe"
						variant="outlined"
						value={confirmNewPassword}
						onChange={(event) =>
							setConfirmNewPassword(event.target.value)
						}
						placeholder="*******"
						type={confirmPasswordIsVisible ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="change la visibilité du mot de passe"
										onClick={() =>
											setConfirmPasswordVisibility(
												!confirmPasswordIsVisible
											)
										}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{confirmPasswordIsVisible ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={
							confirmNewPassword !== "" &&
							newPassword !== confirmNewPassword
						}
						helperText={
							confirmNewPassword !== "" &&
							newPassword !== confirmNewPassword &&
							"Les mots de passe doivent être identiques"
						}
						required
					/>
				</Grid>

				<Grid item xs={12} align="right">
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							onSubmit(newPassword, confirmNewPassword);

							// clear inputs
							setNewPassword("");
							setConfirmNewPassword("");
						}}
					>
						Enregistrer le mot de passe
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default ForgottenPasswordForm;
