import { Grid } from "@material-ui/core";
import React from "react";
import DarkMode from "./components/DarkMode";

/**
 * Component for the User settings
 * @returns
 */
function Settings() {
	return (
		<Grid item xs>
			<DarkMode />
		</Grid>
	);
}

export default Settings;
