import { Avatar, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserSelector } from "../../selectors";
import isUserAdmin from "../../utils/isUserAdmin";
import isUserConnected from "../../utils/isUserConnected";
import roleToText from "../../utils/roleToText";

const useStyles = makeStyles((theme) => ({
	avatar: { width: theme.spacing(12), height: theme.spacing(12) },
	nameText: { marginTop: theme.spacing(2) },
	roleText: { fontStyle: "italic ", fontWeight: "bold" },
	profile: { textDecoration: "none", color: "grey" },
}));

/**
 * Top section of the user menu drawer with picture and user name
 * @returns
 */
function UserMenuHeader({ onClose }) {
	const classes = useStyles();
	const history = useHistory();

	//current user connected
	const user = useSelector(UserSelector);

	return (
		<div>
			{isUserConnected(user) && (
				<>
					{user.data.profileImage ? (
						<Avatar
							className={classes.avatar}
							src={`${process.env.REACT_APP_API_URL}/static/img/${user.data.profileImage}`}
							onClick={(e) => {
								history.push(`/user/${user.data.id}`);
								if (onClose) onClose(e);
							}}
						/>
					) : (
						<Avatar
							onClick={(e) => {
								history.push(`/user/${user.data.id}`);
								if (onClose) onClose(e);
							}}
							className={classes.avatar}
						>
							{user.data.firstname[0]} {user.data.lastname[0]}
						</Avatar>
					)}
					<Typography className={classes.nameText}>
						{user.data.firstname} {user.data.lastname}
					</Typography>
					<Typography className={classes.roleText}>
						{user.roles &&
							(isUserAdmin(user.roles)
								? "Administrateur"
								: roleToText(user.roles))}
					</Typography>
				</>
			)}
		</div>
	);
}

export default UserMenuHeader;
