import { combineReducers, createStore } from "redux";
import MapReducer from "./MapReducer";
import StyleReducer from "./StyleReducer";
import UserReducer from "./UserReducer";
import WelcomeReducer from "./WelcomeReducer";

//Store of the react app : combine all reducers
const store = createStore(
	combineReducers({
		user: UserReducer,
		welcome: WelcomeReducer,
		style: StyleReducer,
		map: MapReducer,
	}),
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
