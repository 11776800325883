import {
	Button,
	Grid,
	IconButton,
	InputAdornment,
	makeStyles,
	TextField,
	Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";
import passwordIsValid from "../../../../utils/passwordIsValid";

const useStyle = makeStyles((theme) => ({
	textInput: {
		width: "100%",
	},
}));

/**
 * Component to display and modify user password
 * @param user current user
 * @param onSubmit callback to call when user modify password
 * @returns
 */
function AccountPassword({ user, onSubmit }) {
	const classes = useStyle();
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [passwordIsVisible, setPasswordVisibility] = useState(false);
	const [newPasswordIsVisible, setNewPasswordVisibility] = useState(false);
	const [confirmPasswordIsVisible, setConfirmPasswordVisibility] =
		useState(false);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<form>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Mot de passe
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Mot de passe actuel"
						variant="outlined"
						value={password}
						onChange={(event) => setPassword(event.target.value)}
						placeholder="*******"
						type={passwordIsVisible ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="Change la visibilité du mot de passe"
										onClick={() =>
											setPasswordVisibility(
												!passwordIsVisible
											)
										}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{passwordIsVisible ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={password !== "" && !passwordIsValid(password)}
						helperText={
							password !== "" &&
							!passwordIsValid(password) &&
							"Le mot de passe doit contenir au moins 8 caractères alphanumériques"
						}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Nouveau mot de passe"
						variant="outlined"
						value={newPassword}
						onChange={(event) => setNewPassword(event.target.value)}
						placeholder="*******"
						type={newPasswordIsVisible ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="Change la visibilité du mot de passe"
										onClick={() =>
											setNewPasswordVisibility(
												!newPasswordIsVisible
											)
										}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{newPasswordIsVisible ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={
							newPassword !== "" && !passwordIsValid(newPassword)
						}
						helperText={
							newPassword !== "" &&
							!passwordIsValid(newPassword) &&
							"Le mot de passe doit contenir au moins 8 caractères alphanumériques"
						}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Confirmation nouveau mot de passe"
						variant="outlined"
						value={confirmNewPassword}
						onChange={(event) =>
							setConfirmNewPassword(event.target.value)
						}
						placeholder="*******"
						type={confirmPasswordIsVisible ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="change la visibilité du mot de passe"
										onClick={() =>
											setConfirmPasswordVisibility(
												!confirmPasswordIsVisible
											)
										}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{confirmPasswordIsVisible ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={
							confirmNewPassword !== "" &&
							newPassword !== confirmNewPassword
						}
						helperText={
							confirmNewPassword !== "" &&
							newPassword !== confirmNewPassword &&
							"Les mots de passe doivent être identiques"
						}
						required
					/>
				</Grid>

				<Grid item xs={12} align="right">
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							onSubmit(password, newPassword, confirmNewPassword);

							// clear inputs
							setPassword("");
							setNewPassword("");
							setConfirmNewPassword("");
						}}
					>
						Enregistrer
					</Button>
				</Grid>
			</Grid>
		</form>
	);
}

export default AccountPassword;
