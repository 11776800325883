/**
 * Connect the user with email and password
 * @param {*} email user email
 * @param {*} password user password
 * @returns
 */
const signInAPI = (email, password) => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/auth/signin`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email, password }),
			}
		);

		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({
							user: json.user,
							roles: json.roles,
							likes: json.likes,
							token: json.token,
							message: json.message,
						});
					} else {
						reject(json.message);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export default signInAPI;
