import {
	Button,
	Container,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const errorStyle = makeStyles((theme) => ({
	root: { height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)` },
	text: {
		textAlign: "center",
	},
	button: {
		marginTop: theme.spacing(4),
	},
}));

/**
 * Component for the Error404 Page
 * @returns
 */
function NotFoundPage() {
	const classes = errorStyle();

	return (
		<Container>
			<Grid
				item
				container
				xs
				alignItems="center"
				justifyContent="center"
				direction="column"
				className={classes.root}
			>
				<Typography
					variant="h1"
					color="primary"
					className={classes.text}
				>
					Page non trouvé ...
				</Typography>
				<Typography variant="h2" className={classes.text}>
					Vous n'êtes pas au bon endroit.
				</Typography>

				<Button
					variant="contained"
					color="primary"
					component={Link}
					to="/"
					className={classes.button}
				>
					Retour à l'accueil
				</Button>
			</Grid>
		</Container>
	);
}

export default NotFoundPage;
