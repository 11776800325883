import {
	Dialog,
	DialogContent,
	DialogTitle,
	makeStyles,
	Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
	zoneText: {
		marginBottom: theme.spacing(4),
	},
}));

function UserAdminDialog({ user, open, handleDialog }) {
	const classes = useStyles();

	return (
		<Dialog
			open={open}
			onClose={() => {
				handleDialog();
			}}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">
				Informations sur l'utilisateur
			</DialogTitle>
			<DialogContent>
				{user && (
					<>
						<Typography className={classes.zoneText}>
							<strong>
								{user.lastname} {user.firstname}
							</strong>
							<br />
							Email : {user.email}
						</Typography>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
}

export default UserAdminDialog;
