import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import React, { useState } from "react";
import UserListContainer from "../containers/UserListContainer";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	heading: {
		fontSize: theme.typography.pxToRem(20),
		flexBasis: "33.33%",
		flexShrink: 0,
	},
}));

function AdminUserTab({ user }) {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<Accordion
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreRounded />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					<Typography className={classes.heading}>
						Administrateurs
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<UserListContainer
						user={user}
						open={expanded === "panel1"}
						role="admin"
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === "panel2"}
				onChange={handleChange("panel2")}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreRounded />}
					aria-controls="panel2bh-content"
					id="panel2bh-header"
				>
					<Typography className={classes.heading}>
						Eleveurs
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<UserListContainer
						user={user}
						open={expanded === "panel2"}
						role="breeder"
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === "panel3"}
				onChange={handleChange("panel3")}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreRounded />}
					aria-controls="panel3bh-content"
					id="panel3bh-header"
				>
					<Typography className={classes.heading}>Bergers</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<UserListContainer
						user={user}
						open={expanded === "panel3"}
						role="sheperd"
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === "panel4"}
				onChange={handleChange("panel4")}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreRounded />}
					aria-controls="panel4bh-content"
					id="panel4bh-header"
				>
					<Typography className={classes.heading}>
						Particuliers
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<UserListContainer
						user={user}
						open={expanded === "panel4"}
						role="user"
					/>
				</AccordionDetails>
			</Accordion>
		</>
	);
}

export default AdminUserTab;
