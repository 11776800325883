import {
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	makeStyles,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { validate } from "email-validator";
import React, { useState } from "react";
import passwordIsValid from "../../../utils/passwordIsValid";

const useStyle = makeStyles((theme) => ({
	paper: {
		[theme.breakpoints.up("md")]: {
			width: "50vw",
		},
		padding: theme.spacing(2),
	},
	subTitle: { marginBottom: theme.spacing(2) },
	textInput: {
		width: "100%",
	},
	checkbox: { marginTop: theme.spacing(2) },
}));

/**
 * Component for user logins signUp step
 * @returns
 */
function SignUpLogin({ values, setValues, handleAccept }) {
	const classes = useStyle();

	//State of the visibility  of the password
	const [passwordIsVisible, setPasswordVisibility] = useState(false);

	//State of the visibility of the confirmation of the password
	const [confirmPasswordIsVisible, setConfirmPasswordVisibility] =
		useState(false);

	//handle when mouse down on password input
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<Paper className={classes.paper} elevation={3}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography className={classes.subTitle} variant="h6">
						Vos identifiants
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Email"
						name="email"
						variant="outlined"
						value={values.email}
						onChange={setValues}
						error={values.email !== "" && !validate(values.email)}
						helperText={
							values.email !== "" &&
							!validate(values.email) &&
							"L'adresse mail doit être valide"
						}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Mot de passe"
						name="password"
						variant="outlined"
						value={values.password}
						onChange={setValues}
						placeholder="*******"
						type={passwordIsVisible ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="Change la visibilité du mot de passe"
										onClick={() =>
											setPasswordVisibility(
												!passwordIsVisible
											)
										}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{passwordIsVisible ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={
							values.password !== "" &&
							!passwordIsValid(values.password)
						}
						helperText={
							values.password !== "" &&
							!passwordIsValid(values.password) &&
							"Le mot de passe doit contenir au moins 8 caractères alphanumériques"
						}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.textInput}
						label="Confirmation du Mot de passe"
						name="confirmPassword"
						variant="outlined"
						value={values.confirmPassword}
						onChange={setValues}
						placeholder="*******"
						type={confirmPasswordIsVisible ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="change la visibilité du mot de passe"
										onClick={() =>
											setConfirmPasswordVisibility(
												!confirmPasswordIsVisible
											)
										}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{confirmPasswordIsVisible ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={
							values.confirmPassword !== "" &&
							values.password !== values.confirmPassword
						}
						helperText={
							values.confirmPassword !== "" &&
							values.password !== values.confirmPassword &&
							"Les mots de passe doivent être identiques"
						}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						className={classes.checkbox}
						control={
							<Checkbox
								checked={values.accept}
								onChange={(event) =>
									handleAccept(event.target.checked)
								}
								color="primary"
							/>
						}
						label="J'accepte les Conditions d'utilisation et la Politique de confidentialité"
					/>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default SignUpLogin;
