import {
	Button,
	Divider,
	Drawer,
	Grid,
	IconButton,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { ClearRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	setUserInformation,
	setUserLikes,
	setUserRoles,
	setUserToken,
} from "../../actions";
import signInAPI from "../../services/signInAPI";
import SignIn from "./SignIn";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "350px",
		marginTop: theme.mixins.toolbar.minHeight,
		[theme.breakpoints.down("sm")]: {
			marginBottom: theme.mixins.toolbar.minHeight,
			marginTop: 0,
			width: "100vw",
		},
	},
	menuClose: { float: "right" },
	divider: { margin: theme.spacing(2) },
	signUpText: { margin: theme.spacing(2) },
}));

/**
 * Menu Drawer for user connection
 * @returns
 */
function SignInDrawer({ open, onClose }) {
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	//fetch account to connect
	const onConnect = (email, password) => (event) => {
		signInAPI(email, password)
			.then(({ message, user, roles, likes, token }) => {
				dispatch(setUserInformation(user));
				dispatch(setUserRoles(roles));
				dispatch(setUserLikes(likes));
				dispatch(setUserToken(token));
				enqueueSnackbar(message, {
					variant: "info",
				});
				onClose(event);
				history.replace("/");
			})
			.catch((err) => {
				enqueueSnackbar(err, {
					variant: "error",
				});
			});
	};

	return (
		<Drawer anchor={"right"} open={open} onClose={onClose}>
			<Grid className={classes.root} container direction="column">
				<Grid item>
					<div className={classes.menuClose}>
						<IconButton onClick={onClose}>
							<ClearRounded color="secondary" />
						</IconButton>
					</div>
				</Grid>
				<Grid item>
					<SignIn onConnect={onConnect} />
				</Grid>
				<Divider className={classes.divider} />
				<Grid item align="center">
					<Typography className={classes.signUpText}>
						Si vous n'êtes pas encore inscrit
					</Typography>
					<Button
						className={classes.button}
						variant="contained"
						color="primary"
						onClick={(event) => {
							history.push("/sign-up");
							onClose(event);
						}}
					>
						Inscription
					</Button>
				</Grid>
			</Grid>
		</Drawer>
	);
}
export default SignInDrawer;
