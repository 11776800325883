/**
 * Fetch all zones from the server
 * @returns
 */
const getZonesAPI = () => {
	return new Promise((resolve, reject) => {
		const request = new Request(
			`${process.env.REACT_APP_API_URL}/zone/get`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		fetch(request)
			.then((res) => {
				res.json().then((json) => {
					if (json.success) {
						resolve({ zones: json.zones });
					} else {
						reject(json.message);
					}
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export default getZonesAPI;
